import { BsThreeDots } from "react-icons/bs";
import classes from "./TransactionCard.module.css";
import { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { firebaseContext } from "../Context/FirebaseContext";
import { doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { BsSquare, BsCheckSquare } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { AlertDialogDemo } from "../RadixComponents/alertDialogDemo";
import { collection, query, where } from "firebase/firestore";

export default function TransactionCard({ order, allChecked, markChecked, openSnackbar, setAlertOpen }) {
  const [showActions, setShowActions] = useState(false);
  const [checked, setChecked] = useState(allChecked);
  const [orderStatus, setOrderStatus] = useState(order?.customer?.status);
  const [colors, setColors] = useState({
    chip: { backgroundColor: "" },
    orderDot: { backgroundColor: "" },
    text: { color: "" },
  });
  const [isRefunding, setIsRefunding] = useState(false);

  useEffect(() => {
    setChecked(allChecked);
  }, [allChecked, order]);

  const { db } = useContext(firebaseContext);

  const handleActionClick = async (action) => {
    const isUserId = order?.customer?.userID !== "";
    console.log("isUserId: ", isUserId);

    if (action === "fulfil") {
      //find the customers id based on the stripeID which is defined as customerID in the database from the order number
      const orderRef = doc(db, "orders", order?.id);
      const orderRefData = await getDoc(orderRef);
      const usersRef = collection(db, "users");

      const q = query(usersRef, where("squareID", "==", orderRefData.data()?.customer?.customerID));
      let docUserID = "";

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        docUserID = doc.id;
      });

      console.log("order", order);

      if (isUserId) {
        const customerOrderRef = doc(db, "users", docUserID, "orders", order?.id);

        await updateDoc(customerOrderRef, {
          "customer.status": "Completed",
        });
      }

      await updateDoc(orderRef, {
        "customer.status": "Completed",
      });

      // setOrderStatus("Completed");
      setShowActions(false);
    }

    if (action === "refund") {
      handlRefundOrder();
    }
  };

  const handleDotsClick = (event) => {
    setShowActions(!showActions);
  };

  const handleMarkChecked = () => {
    setChecked(!checked);
    markChecked(checked ? "remove" : "add", order?.id);
  };

  const handlRefundOrder = async () => {
    setIsRefunding(true);
    await fetch("https://justherbal.herokuapp.com/refund", {
      // await fetch("http://localhost:4000/refund", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderID: order.id,
        paymentID: order.paymentID, // Use the correct paymentID from orderData
        userID: order?.customer?.userID,
      }),
    });

    setOrderStatus("Refunded");
    setShowActions(false);

    openSnackbar();
    setIsRefunding(false);
  };

  const triggerModal = () => {
    setShowActions(false);
  };

  useEffect(() => {
    getColor(orderStatus);
  }, [orderStatus]);

  const getColor = (orderStatus) => {
    if (orderStatus === "Completed") {
      setColors({
        chip: { backgroundColor: "#a1ffac" },
        orderDot: { backgroundColor: "#5ed94b" },
        text: { color: "#247817" },
      });
    } else if (orderStatus === "Refunded") {
      setColors({
        chip: { backgroundColor: "#ff6d7e" },
        orderDot: { backgroundColor: "#f44336" },
        text: { color: "#fff" },
      });
    } else {
      setColors({
        chip: { backgroundColor: "rgb(255, 237, 159)" },
        orderDot: { backgroundColor: "rgb(255, 211, 13)" },
        text: { color: "rgb(115, 94, 0)" },
      });
    }
  };

  const actionsContainerRef = useRef();
  const dotsRef = useRef();
  const refundRef = useRef();

  const handleClickOutside = (event) => {
    if (showActions && actionsContainerRef?.current && dotsRef?.current && refundRef?.current) {
      if (
        !dotsRef?.current?.contains(event.target) &&
        !actionsContainerRef?.current?.contains(event.target) &&
        !refundRef?.current?.contains(event.target)
      ) {
        setShowActions(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [showActions]);

  const { pathname } = useLocation();

  return (
    <div className={classes.container}>
      {pathname !== "/admin/orders/all" && (
        <div>
          {checked ? (
            <BsCheckSquare onClick={handleMarkChecked} style={{ marginRight: "2em", height: "2em" }} color="grey" />
          ) : (
            <BsSquare onClick={handleMarkChecked} style={{ marginRight: "2em", height: "2em" }} color="grey" />
          )}
        </div>
      )}
      <Link to={`/admin/orders/${order?.id}`} className={classes.container} style={{ borderBottom: "none" }}>
        <span style={colors?.chip} className={`${classes.chip}`}>
          <div style={colors?.orderDot} className={classes.orderDot}></div>
          <span style={colors?.text} name="status">
            {orderStatus}
          </span>
        </span>

        {/* Customer Name */}
        <span className={classes.orderTab}>
          <span>
            {`${order?.customer?.name.split(" ")[0] || ""}  ${order?.customer?.name?.split(" ")[1]?.slice(0, 1) || ""}`}
          </span>
          <span>{order?.customer?.phone || "No Phone"}</span>
        </span>
        {/* Order Total */}
        <span to={`/admin/orders/${order?.id}`} className={classes.orderTab}>
          <span>
            {(order?.customer?.total).toLocaleString("en-GB", {
              style: "currency",
              currency: "GBP",
            })}
          </span>
          <span>{order?.customer?.date || "No Date"}</span>
        </span>
      </Link>

      <div className={classes.dotsContainer} ref={dotsRef}>
        <BsThreeDots onClick={handleDotsClick} className={classes.dots} />
      </div>
      {showActions && (
        <div ref={actionsContainerRef} className={classes.actionsContainer}>
          <div onClick={() => handleActionClick("fulfil")} className={classes.action}>
            Mark Completed
          </div>

          <AlertDialogDemo
            ref={refundRef}
            setShowActions={setShowActions}
            handlRefundOrder={handlRefundOrder}
            isRefunding={isRefunding}
          />

          <IoIosClose
            onClick={() => setShowActions(false)}
            style={{
              position: "absolute",
              top: "15px",
              right: "5px",
              marginRight: "0.5em",
            }}
            size={25}
          />
        </div>
      )}
    </div>
  );
}
