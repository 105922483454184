import classes from "./AdminHeader.module.css";
import { BsFillInboxFill } from "react-icons/bs";
import Logo from "../Assets/logoTransparent.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "../Context/UserContext";

export default function AdminHeader() {
  const { pathname } = useLocation();
  const { handleLogOut } = useContext(UserContext);
  const path = pathname.split("/")[2];

  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div
        onClick={() => setShowMenu(!showMenu)}
        className={classes.menuContainer}
      >
        <BsFillInboxFill color="white" style={{ marginRight: "1em" }} />
        <div className={classes.path} style={{ marginRight: "auto" }}>
          {path}
        </div>
      </div>

      {showMenu && (
        <div className={classes.menu}>
          <Link to={"/admin/orders"}>
            <div
              onClick={() => setShowMenu(false)}
              className={classes.menuItem}
            >
              Orders
            </div>
          </Link>
          <Link to={"/admin/products"}>
            <div
              onClick={() => setShowMenu(false)}
              className={classes.menuItem}
            >
              Products
            </div>
          </Link>
          <Link to={"/admin/discount"}>
            <div
              onClick={() => setShowMenu(false)}
              className={classes.menuItem}
            >
              Discount
            </div>
          </Link>
          <div
            onClick={() => handleLogOut({ navigate: navigate("/") })}
            className={classes.menuItem}
          >
            Logout
          </div>
        </div>
      )}

      <Link to={"/"}>
        <img name="logo" style={{ height: "1.5em" }} src={Logo} alt="logo" />
      </Link>
    </div>
  );
}
