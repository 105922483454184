import { useContext, useEffect, useState } from "react";
import classes from "./Login.module.css";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { ReactComponent as BlurBackground } from "../Assets/shapeBlur.svg";
import { CgArrowLeftR } from "react-icons/cg";
import { Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import { FcGoogle } from "react-icons/fc";
import ResetPassword from "./ResetPassword";

export default function Login() {
  const [activeScreen, setActiveScreen] = useState("signin");
  const [isAnimating, setIsAnimating] = useState(false);
  const { handleLogin, googleSignIn, error } = useContext(UserContext);
  const { user } = useContext(UserContext);
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const [showErrors, setShowErrors] = useState(false);

  const googleLogin = async () => {
    await googleSignIn({
      navigate: () => {
        navigate("/", { replace: true });
      },
    });
  };

  useEffect(() => {
    if (isAnimating) {
      const loginForm = document.querySelector(`.${classes.loginForm}`);
      loginForm?.addEventListener("animationend", handleAnimationEnd);

      loginForm?.classList.remove(classes.flip);
      setTimeout(() => loginForm.classList.add(classes.flip), 10);

      const loginFormContent = document.querySelector(
        `.${classes.loginFormContent}`
      );
      loginFormContent.style.transform = "rotateY(180deg)";
    }
  }, [isAnimating]);

  const handleScreenChange = (screen) => {
    if (activeScreen !== screen) {
      const screenButtons = document.querySelectorAll(
        `.${classes.screenButtonsContainer} button`
      );
      const activeButton = document.querySelector(
        `.${classes.screenButtonsContainer} button[name=${screen}]`
      );

      screenButtons?.forEach((button) =>
        button.classList.remove(classes.activeScreenButton)
      );
      activeButton?.classList.add(classes.activeScreenButton);

      if (!isAnimating) {
        setIsAnimating(true);
        setTimeout(() => {
          setActiveScreen(screen);
        }, 250);
      }
    }
  };

  const handleAnimationEnd = () => {
    const loginForm = document.querySelector(`.${classes.loginForm}`);
    loginForm?.removeEventListener("animationend", handleAnimationEnd);
    setIsAnimating(false);
  };

  const navigate = useNavigate();

  if (user?.admin) {
    return <Navigate to="/admin/orders" replace={true} />;
  } else if (user) {
    return <Navigate to="/dashboard" replace={true} />;
  } else {
    return (
      <div className={classes.container}>
        <div className={classes.loginForm}>
          <div className={`${classes.loginFormContent}`}>
            <div className={classes.loginFormHeader} style={{ zIndex: "1" }}>
              <div
                className={classes.backArrow}
                onClick={() => navigate(-1, { replace: true })}
              >
                <CgArrowLeftR color="#151515" size={30} />
              </div>

              <h1>{activeScreen === "signin" ? "Welcome back" : "Welcome"}</h1>
              {!showErrors && (
                <p>
                  {activeScreen === "signin"
                    ? "Sign in to your account"
                    : activeScreen === "signup"
                    ? "Sign up with the details below"
                    : "Enter your details below"}
                </p>
              )}

              {error && (
                <div className={classes.errorContainer}>
                  <p className={classes.error}>{error}</p>
                </div>
              )}

              {showErrors && (
                <div className={classes.errorContainer}>
                  <p className={classes.error}>{errors?.email}</p>
                  <p className={classes.error}>{errors?.password}</p>
                </div>
              )}
            </div>
            {activeScreen !== "forgot" && (
              <div className={classes.screenButtonsContainer}>
                <button
                  name="signin"
                  onClick={() => handleScreenChange("signin")}
                  className={classes.activeScreenButton}
                >
                  Sign In
                </button>
                <button
                  name="signup"
                  onClick={() => handleScreenChange("signup")}
                >
                  Sign Up
                </button>
              </div>
            )}

            {activeScreen === "signin" ? (
              <SignIn
                errors={errors}
                setErrors={setErrors}
                handleLogin={handleLogin}
                setShowErrors={setShowErrors}
              />
            ) : activeScreen === "signup" ? (
              <SignUp
                errors={errors}
                setErrors={setErrors}
                handleLogin={handleLogin}
                setShowErrors={setShowErrors}
              />
            ) : (
              <ResetPassword setActiveScreen={setActiveScreen} />
            )}
            {activeScreen !== "forgot" && (
              <div className={classes.alternativeContainer}>
                <div className={classes.alternateTopDiv}>
                  <div className={classes.divider}></div>
                  <span>Or Continue With</span>
                  <div className={classes.divider}></div>
                </div>
                <div className={classes.badgeContainer}>
                  <div onClick={googleLogin} className={classes.icon}>
                    <FcGoogle style={{ height: "100%", width: "2em" }} />
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <div className={classes.shape}>
            <BlurBackground />
          </div> */}
        </div>

        {activeScreen === "signin" && (
          <button
            className={classes.forgot}
            onClick={() => handleScreenChange("forgot")}
          >
            Forgotten Password?
          </button>
        )}
      </div>
    );
  }
}
