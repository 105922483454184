import { useContext, useEffect, useRef, useState } from "react";
import classes from "./UserDash.module.css";
import { UserContext } from "../Context/UserContext";
import { ReactComponent as Loader } from "../Assets/loader.svg";
import {
  PhoneAuthProvider,
  RecaptchaVerifier,
  getAuth,
  signInWithEmailAndPassword,
  updateEmail,
  updatePassword,
  updatePhoneNumber,
  updateProfile,
} from "firebase/auth";
import { collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { firebaseContext } from "../Context/FirebaseContext";

export default function UserPersonalDetails({ user }) {
  const { db } = useContext(firebaseContext);

  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState(user?.email);
  const [telephone, setTelephone] = useState(user?.phoneNumber || "");
  const [currentPassword, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isEditingPersonal, setIsEditingPersonal] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingTelephone, setIsEditingTelephone] = useState(false);
  const [verificationId, setVerificationId] = useState("");
  const [hasRequestedOTP, setHasRequestedOTP] = useState(false);
  const [buttonText, setButtonText] = useState("Request OTP");
  const [isChecked, setIsChecked] = useState(false);
  const [telephoneIsValid, setTelephoneIsValid] = useState(false);

  const auth = getAuth();
  const userRef = doc(db, "users", user?.uid);

  const handleTelephoneChange = (event) => {
    let newValue = event.target.value;
    const prefix = "+44";

    if (newValue.startsWith(prefix)) {
      if (newValue[3] === "0") {
        newValue = prefix;
      } else {
        newValue = newValue.substring(0, 13);
      }
    } else {
      newValue = prefix;
    }

    setTelephone(newValue);
    validateTelephone(newValue);
  };

  const validateTelephone = (telephone) => {
    const prefix = "+44";
    const isValid =
      telephone.length === 13 &&
      telephone.startsWith(prefix) &&
      telephone[3] !== "0";

    setTelephoneIsValid(isValid);
  };

  useEffect(() => {
    if (user) {
      const checkSubscribed = async () => {
        const userDoc = await getDoc(userRef);
        const { displayName, isSubscribed } = userDoc?.data() || {};
        let firstName = "";
        let lastName = "";

        if (displayName) {
          [firstName, lastName] = displayName?.split(" ");
        }

        console.log(firstName, lastName);

        setFirstName(firstName);
        setLastName(lastName);
        setIsChecked(isSubscribed);
      };

      checkSubscribed();
    }
  }, []);

  const handleUpdatePersonal = async () => {
    setIsLoading(true);

    const first =
      firstName?.slice(0, 1).toUpperCase() + firstName?.slice(1).toLowerCase();
    const last =
      lastName?.slice(0, 1).toUpperCase() + lastName?.slice(1).toLowerCase();

    await updateProfile(auth?.currentUser, {
      displayName: `${first} ${last}`,
    });

    await updateDoc(userRef, {
      displayName: `${firstName} ${lastName}`,
    });

    setTimeout(() => {
      setIsLoading(false);
      setIsEditingPersonal(false);
    }, 300);
  };

  const handleUpdateEmail = async () => {
    setIsLoading(true);
    await updateEmail(auth?.currentUser, email.toString());

    await updateDoc(userRef, {
      email,
    });

    setTimeout(() => {
      setIsLoading(false);
      setIsEditingEmail(false);
    }, 300);
  };

  const handleUpdatePassword = async () => {
    setIsLoading(true);
    await signInWithEmailAndPassword(auth, email, currentPassword).then(() => {
      const isConfirmed = password === confirmPassword;
      if (isConfirmed) {
        updatePassword(auth?.currentUser, password.toString());
      }
      setTimeout(() => {
        setIsLoading(false);
        setIsEditingPassword(false);
        setCurrentPassword("");
        setPassword("");
        setConfirmPassword("");
      }, 300);
    });
  };

  const telephoneRef = useRef();

  const generateRecaptcha = () => {
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear();
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          setButtonText("Submit");
        },
      },
      auth
    );
  };

  const handleUpdatePhone = async () => {
    generateRecaptcha();
    setHasRequestedOTP(true);

    let appVerifier = window.recaptchaVerifier;
    const provider = new PhoneAuthProvider(auth);

    const verificationId = await provider.verifyPhoneNumber(
      telephone,
      appVerifier
    );

    setVerificationId(verificationId);
  };

  const verifyOTP = async () => {
    setIsLoading(true);

    const phoneCredential = PhoneAuthProvider.credential(
      verificationId,
      telephoneRef.current.value
    );
    await updatePhoneNumber(user, phoneCredential);

    await updateDoc(userRef, {
      phoneNumber: telephone,
    });

    telephoneRef.current.value = "";

    setButtonText("Success!");

    setTimeout(() => {
      setIsLoading(false);
      setHasRequestedOTP(false);
      setButtonText("Request OTP");
      setIsEditingTelephone(false);
    }, 300);
  };

  const handleSubscribe = async () => {
    setIsChecked(!isChecked);

    await updateDoc(userRef, {
      isSubscribed: !isChecked,
    });
  };

  return (
    <>
      <div className={classes.body}>
        <div className="d-flex align-items-center">
          <h5>Personal Details</h5>
          <span
            onClick={(e) => setIsEditingPersonal(!isEditingPersonal)}
            className={classes.edit}
          >
            Edit
          </span>
        </div>
      </div>

      <div className={classes.personalDetails}>
        <div className="d-flex gap-2">
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            disabled={!isEditingPersonal}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            disabled={!isEditingPersonal}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        {isEditingPersonal && (
          <button onClick={handleUpdatePersonal}>
            {isLoading ? (
              <Loader style={{ height: "2em", width: "2em" }} />
            ) : (
              "Save"
            )}
          </button>
        )}
      </div>

      <div className={classes.body}>
        <div className="d-flex align-items-center">
          <h5>Email</h5>
          <span
            onClick={(e) => setIsEditingEmail(!isEditingEmail)}
            className={classes.edit}
          >
            Edit
          </span>
        </div>
        <div className={classes.personalDetails}>
          <div className="d-flex gap-2">
            <input
              type="email"
              placeholder="Enter your email address"
              value={email}
              disabled={!isEditingEmail}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {isEditingEmail && (
            <button onClick={handleUpdateEmail}>
              {isLoading ? (
                <Loader style={{ height: "2em", width: "2em" }} />
              ) : (
                "Save"
              )}
            </button>
          )}
        </div>
      </div>

      <div className={classes.body}>
        <div className="d-flex align-items-center">
          <h5>Password</h5>
          <span
            onClick={(e) => setIsEditingPassword(!isEditingPassword)}
            className={classes.edit}
          >
            Edit
          </span>
        </div>
        <div className={classes.personalDetails}>
          <input
            type="password"
            placeholder="Current password"
            value={currentPassword}
            disabled={!isEditingPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          {isEditingPassword && (
            <>
              <input
                type="password"
                placeholder="Enter your new password"
                value={password}
                disabled={!isEditingPassword}
                onChange={(e) => setPassword(e.target.value)}
              />

              <input
                type="password"
                placeholder="Confirm your password"
                value={confirmPassword}
                disabled={!isEditingPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </>
          )}
          {isEditingPassword && (
            <button onClick={handleUpdatePassword}>
              {isLoading ? (
                <Loader style={{ height: "2em", width: "2em" }} />
              ) : (
                "Save"
              )}
            </button>
          )}
        </div>

        <div className={classes.body}>
          {/* <div className="d-flex align-items-center">
            <h5>Phone Number</h5>
            <span
              onClick={(e) => setIsEditingTelephone(!isEditingTelephone)}
              className={classes.edit}
            >
              Edit
            </span>
          </div> */}
          <div className={classes.personalDetails}>
            {/* <div className="d-flex gap-2">
              <input
                type="tel"
                placeholder="Enter your phone number"
                value={telephone}
                disabled={!isEditingTelephone}
                onChange={handleTelephoneChange}
              />
              <input
                ref={telephoneRef}
                type="text"
                placeholder="Enter your OTP"
                disabled={!hasRequestedOTP}
              />
            </div> */}

            <div className={classes.checkDiv}>
              <input
                type="checkbox"
                id="checkbox"
                checked={isChecked}
                onChange={handleSubscribe}
              />
              <label htmlFor="checkbox">
                Subscribe to exclusive discounts and free products
              </label>
            </div>

            {/* <div id="recaptcha-container"></div> */}
            {/* {isEditingTelephone && (
              <div className="d-flex">
                {isLoading ? (
                  <Loader style={{ height: "2em", width: "2em" }} />
                ) : (
                  <button
                    id="otpBtn"
                    onClick={hasRequestedOTP ? verifyOTP : handleUpdatePhone}
                    disabled={!telephoneIsValid}
                  >
                    {buttonText}
                  </button>
                )}
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
}
