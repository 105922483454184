import { useCallback, useEffect, useState } from "react";
import classes from "./AdminProductDetails.module.css";
import { useDropzone } from "react-dropzone";
import DropBox from "./Dropbox";
import ShowImage from "./ShowImg";
import { uuid } from "uuidv4";
import { useNavigate } from "react-router-dom";
import { BsXSquare } from "react-icons/bs";
import { ReactComponent as Loader } from "../Assets/loader.svg";

export default function AdminProductDetails() {
  const [images, setImages] = useState([]);
  const [formIsValid, setFormIsValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formDetails, setFormDetails] = useState({
    name: "",
    quantity: "",
    price: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    setFormIsValid(
      [
        images.length,
        formDetails.name.length,
        formDetails.quantity,
        formDetails.price,
      ].every((value) => value > 0)
    );
  }, [images, formDetails]);

  const uploadImage = async () => {
    const [firstImage, secondImage] = images.map((image) => image?.src);

    const formData1 = new FormData();
    formData1.append("file", firstImage);
    formData1.append("upload_preset", preset_key);

    const response1 = await fetch(
      `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
      {
        method: "POST",
        body: formData1,
      }
    );
    const primaryUrl = (await response1.json()).url;

    let secondaryUrl;
    if (secondImage) {
      const formData2 = new FormData();
      formData2.append("file", secondImage);
      formData2.append("upload_preset", preset_key);

      const response2 = await fetch(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        {
          method: "POST",
          body: formData2,
        }
      );
      secondaryUrl = (await response2.json()).url;
    }

    return { primaryUrl, secondaryUrl };
  };

  const preset_key = process.env.REACT_APP_CLOUDINARY_PRESET_KEY;
  const cloud_name = process.env.REACT_APP_CLOUD_NAME;

  const handleCreateProduct = async (e) => {
    setLoading(true);
    debugger;
    try {
      if (images.length === 1) {
        console.log("images: ", images);
        const { primaryUrl } = await uploadImage();

        const product = {
          name: formDetails?.name,
          description: +formDetails?.quantity,
          images: [primaryUrl],
          primaryImg: primaryUrl,
          secondaryImg: "",
          active: true,
          price: formDetails?.price,
          id: formDetails?.id,
          stripeID: formDetails?.stripeID,
          quantity: +formDetails?.quantity,
        };

        console.log("product: ", product);
        const response = await fetch(
          "https://justherbal.herokuapp.com/create-product",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ product }),
          }
        );
        console.log(response);
        navigate("/admin/products");
      } else {
        const { primaryUrl, secondaryUrl } = await uploadImage();

        const product = {
          name: formDetails?.name,
          description: +formDetails?.quantity,
          images: [primaryUrl, secondaryUrl],
          primaryImg: primaryUrl,
          secondaryImg: secondaryUrl,
          active: true,
          price: formDetails?.price,
        };

        const response = await fetch(
          "https://justherbal.herokuapp.com/create-product",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ product }),
          }
        );

        navigate("/admin/products");
      }
    } catch (error) {
      alert(error.message);
      setLoading(false);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages((prevState) => [
          ...prevState,
          { id: uuid(), src: e.target.result },
        ]);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.bodyHeader}>
        {/* <div className={classes.bodyHeaderTop}>
          <div className={classes.bodyHeaderItem}>Create Product</div>
        </div> */}
      </div>

      <form className={classes.productDetailsContainer}>
        <div
          style={{ marginBottom: "2em" }}
          className="d-flex align-items-center justify-content-between"
        >
          <span
            style={{
              margin: "0",
              fontSize: "1.3em",
              fontWeight: "600",
            }}
          >
            Create a Product
          </span>
          <BsXSquare onClick={() => navigate(-1)} size={20} color="grey" />
        </div>

        <label htmlFor="name">Product Name</label>
        <input
          value={formDetails.name}
          autocorrect="off"
          id="name"
          onChange={(e) => {
            setFormDetails({ ...formDetails, name: e.target.value });
          }}
          type="text"
          required
        />
        <label htmlFor="quantity">Pack Size</label>
        <input
          id="quantity"
          value={formDetails.quantity}
          onChange={(e) => {
            setFormDetails({ ...formDetails, quantity: e.target.value });
          }}
          type="number"
          required
        />

        <div className={classes.productPricing}>
          <label htmlFor="price">Price</label>
          <input
            id="price"
            value={formDetails.price}
            onChange={(e) => {
              setFormDetails({ ...formDetails, price: e.target.value });
            }}
            type="number"
            required
          />
        </div>

        <DropBox images={images} setImages={setImages} onDrop={onDrop} />
        <ShowImage images={images} setImages={setImages} />
      </form>

      <div className={classes.footer}>
        <button
          style={{
            backgroundColor: `${formIsValid ? "#39ca5a" : "#F2F2F2."}`,
            color: `${formIsValid ? "#FFF" : "#D3D3D3"}`,
            fontWeight: `${formIsValid ? "bold" : "normal"}`,
          }}
          disabled={!formIsValid || loading}
          className={classes.footerButton}
          onClick={handleCreateProduct}
        >
          {loading ? (
            <Loader style={{ width: "2em", height: "2em", color: "white" }} />
          ) : (
            "Create Product"
          )}
        </button>
      </div>
    </div>
  );
}
