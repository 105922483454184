import { useContext, useEffect, useState } from "react";
import classes from "./UserDash.module.css";
import { UserContext } from "../Context/UserContext";
import { updateDoc, doc, onSnapshot } from "firebase/firestore";
import { firebaseContext } from "../Context/FirebaseContext";
import { ReactComponent as Loader } from "../Assets/loader.svg";

export default function UserAddressBook() {
  const [isEditingAddress, setIsEditingAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useContext(UserContext);
  const { db } = useContext(firebaseContext);

  const [userData, setUserData] = useState(null);
  const [address, setAddress] = useState();

  useEffect(() => {
    if (user) {
      onSnapshot(doc(db, "users", user.uid), (doc) => {
        setUserData(doc.data());
        setAddress({
          street: doc.data()?.address?.street,
          city: doc.data()?.address?.city,
          postcode: doc.data()?.address?.postcode,
        });
      });
    }
  }, [user]);

  const handleChange = (e) => {
    setAddress({
      ...address,
      [e.target.name]: e.target.value,
    });
  };

  const userRef = doc(db, "users", user?.uid);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    await updateDoc(userRef, {
      address,
    });

    setTimeout(() => {
      setIsLoading(false);
      setIsEditingAddress(false);
    }, 300);
  };

  if (userData) {
    return (
      <div className={classes.body}>
        <div className="d-flex align-items-center">
          <h5>Saved Address</h5>
          <span
            onClick={() => setIsEditingAddress(!isEditingAddress)}
            className={classes.edit}
          >
            {isEditingAddress ? "Cancel" : "Edit"}
          </span>
        </div>
        <div className={classes.personalDetails}>
          <div className="d-flex gap-2">
            <form className={classes.formInput} onSubmit={handleSubmit}>
              <label>
                Street:
                <input
                  type="text"
                  name="street"
                  placeholder="Street"
                  disabled={!isEditingAddress}
                  value={address?.street}
                  onChange={handleChange}
                />
              </label>
              <label>
                City:
                <input
                  type="text"
                  placeholder="City"
                  name="city"
                  disabled={!isEditingAddress}
                  value={address?.city}
                  onChange={handleChange}
                />
              </label>

              <label>
                Postcode:
                <input
                  type="text"
                  name="postcode"
                  placeholder="Postcode"
                  disabled={!isEditingAddress}
                  value={address?.postcode}
                  onChange={handleChange}
                />
              </label>
              {isEditingAddress && (
                <button type="submit">
                  {isLoading ? (
                    <Loader style={{ height: "2em", width: "2em" }} />
                  ) : (
                    "Save"
                  )}
                </button>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}
