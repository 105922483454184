import classes from "./AdminProductItem.module.css";
import { BsThreeDots } from "react-icons/bs";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { firebaseContext } from "../Context/FirebaseContext";
import { doc, updateDoc } from "firebase/firestore";
import { BsSquare, BsCheckSquare } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";

export default function TransactionCard({ product }) {
  const [showActions, setShowActions] = useState(false);

  const navigate = useNavigate();

  const handleDotsClick = (event) => {
    setShowActions(!showActions);
  };

  const handleDeleteProduct = async () => {
    await fetch("https://justherbal.herokuapp.com/delete-product", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ stripeID: product?.stripeID, id: product?.id }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.success) {
          navigate("/admin/products");
        }
      });
  };

  return (
    <div className={classes.container}>
      <div></div>
      <Link
        to={`/admin/edit-product/${product?.id}`}
        className={classes.container}
        style={{ borderBottom: "none" }}
      >
        <img src={product?.image} alt={product?.name} />
        <span className={classes.orderTab}>
          <span>{product?.name}</span>
          <span>{product?.description || "No Phone"}</span>
        </span>
        <span className={classes.orderTab}>
          <span>
            {(product?.price).toLocaleString("en-GB", {
              style: "currency",
              currency: "GBP",
            })}
          </span>
          <span>{product?.brand || ""}</span>
        </span>
      </Link>

      <BsThreeDots onClick={handleDotsClick} className={classes.dots} />
      {showActions && (
        <div className={classes.actionsContainer}>
          <div
            onClick={() => navigate(`/admin/edit-product/${product?.id}`)}
            className={classes.action}
          >
            Edit Product
          </div>
          <div onClick={handleDeleteProduct} className={classes.action}>
            Delete Product
          </div>
          <IoIosClose
            onClick={() => setShowActions(false)}
            style={{
              position: "absolute",
              top: "15px",
              right: "5px",
              marginRight: "0.5em",
            }}
            size={25}
          />
        </div>
      )}
    </div>
  );
}
