import { Navigate, Outlet } from "react-router-dom";

export default function ProtectedRouteLayout({ user, redirectPath = "/" }) {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <div>
      <Outlet />
    </div>
  );
}
