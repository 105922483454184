import { useContext, useEffect, useState } from "react";
import { UserContext } from "../Context/UserContext";
import classes from "./UserOrders.module.css";
import { collection, onSnapshot } from "firebase/firestore";
import { firebaseContext } from "../Context/FirebaseContext";
import OrderCard from "./OrderCard";
import { useNavigate } from "react-router-dom";

export default function UserOrders() {
  const [orders, setOrders] = useState(null);
  const { user } = useContext(UserContext);
  const { db } = useContext(firebaseContext);
  const { products } = useContext(firebaseContext);

  useEffect(() => {
    if (user) {
      onSnapshot(collection(db, "users", user?.uid, "orders"), (snapshot) => {
        setOrders(snapshot.docs.map((doc) => doc.data()));
      });
    }
  }, [user]);

  const navigate = useNavigate();

  const userOrders = orders?.map((order) => {
    const orderProducts = order?.items?.map((item) => {
      console.log(item);
      const product = products?.find(
        (product) => product?.stripeID === item.price
      );
      return { ...item, product };
    });
    console.log("orderProducts: ", orderProducts);
    return { ...order, items: orderProducts };
  });

  return (
    <div className={classes.container}>
      <h2>Browse orders</h2>
      <span
        onClick={() => navigate("/dashboard")}
        className={classes.accountLink}
      >
        Back to account
      </span>
      {/* sort orders  */}
      <div className={classes.body}>
        {userOrders && userOrders?.length > 0 ? (
          userOrders?.map((item) => {
            return (
              <OrderCard
                key={Math.random()}
                order={item?.items}
                fullOrder={
                  userOrders.filter((order) => order.id === item.id)[0]
                }
              />
            );
          })
        ) : (
          <h2
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "5em",
            }}
          >
            You have no orders
          </h2>
        )}
      </div>
    </div>
  );
}
