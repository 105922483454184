import { useLocation, useNavigate } from "react-router-dom";
import classes from "./Product.module.css";
import {
  Profiler,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { firebaseContext } from "../Context/FirebaseContext";
import { FaGlobeAmericas } from "react-icons/fa";
import { BsFillCartCheckFill, BsTruck } from "react-icons/bs";
import { RiSecurePaymentLine } from "react-icons/ri";
import dot from "../Assets/dot.gif";
import ProductScroll from "./ProductScroll";
import { CartContext } from "../Context/CartContext";
import { ReactComponent as Klarna } from "../Assets/klarna.svg";
import Clearpay from "../Assets/clearpay.png";

export default function Product() {
  const { pathname } = useLocation();
  const { products } = useContext(firebaseContext);
  const [activeTab, setActiveTab] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [cartText, setCartText] = useState("ADD TO CART");

  const productID = pathname.slice(9);
  const product = products?.find((product) => product.id === productID);

  const { handleAddToCart, cartIsVisible } = useContext(CartContext);

  const addProductToCart = () => {
    const button = document.getElementById("cartBtn");
    const secondButton = document.getElementById("secondCartBtn");
    button.style.backgroundColor = "#39ca5a";
    secondButton.style.backgroundColor = "#39ca5a";
    setCartText(<BsFillCartCheckFill size={18} />);

    setTimeout(() => {
      button.style.backgroundColor = "#151515";
      secondButton.style.backgroundColor = "#151515";
      setCartText("Add to Cart");
    }, 900);

    handleAddToCart({ product, prodQuantity: 1 });
  };

  const handleChangeImage = () => {
    if (product?.imageSecondary !== "") {
      imageRef.current.src = product.imageSecondary;
    }
  };

  const productScrollArray = useMemo(() => {
    const featuredIDs = [
      "d87f5167-b1b8-4995-b88d-7507523884ce", // Blue 10
      "0088c218-3554-43b0-821e-d3197842ea65",
      "53a7cf44-abfd-4f92-a924-43be425ed9bd",
      "69b7cbaf-9532-4ded-8b75-9e785a143eac",
      "889960a4-8986-440e-9e1a-776111bd975e",
      "825c0e8e-de32-4ad9-8eeb-5a0d0fd411dc",
      "3c7a847f-f041-4d27-ac68-32e2e076bbc7",
      "9a71580f-64da-402c-9090-711ef301bbba",
      "ad06f237-c736-46cc-a454-873a367c7a92",
      "56fbcbc0-4d02-454e-8e5f-0511f070092b",
      "e7d97eff-14dc-4ec6-ba0d-b729c779f267",
      "34f0158c-75de-4284-8425-81b33fcfea3f",
      "e9659eda-f92e-4254-a41c-396db9a9e199",
      // "b388eca4-425a-41af-832b-d986fcd90aad",
    ];

    return featuredIDs.map((id) =>
      products?.find((product) => product?.id === id)
    );
  }, [products]);

  const imageRef = useRef();

  const navigate = useNavigate();

  const cartRef = useRef();
  const secondButtonRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const shouldHideCart = window.scrollY >= 162.5 && window.innerWidth < 862;
      cartRef.current.style.display = shouldHideCart ? "none" : "block";
      secondButtonRef.current.style.display = shouldHideCart ? "block" : "none";
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getMG = () => {
    if (product?.name.includes("Blue")) {
      return "100";
    } else if (product?.name.includes("Red")) {
      return "200";
    } else if (product?.name.includes("Yellow")) {
      return "60";
    } else if (product?.name.includes("Sample")) {
      return "100";
    } else if (product?.name.includes("Energy")) {
      return "200";
    } else if (product?.name.includes("Sleepiclone")) {
      return "10";
    } else if (product?.name.includes("Orange")) {
      return "150";
    } else if (product?.name.includes("Pink")) {
      return "100";
    } else if (product?.name.includes("Tapen")) {
      return "100";
    } else {
      return "100";
    }
  };

  const getProductName = () => {
    // Check if the product is a bundle by looking for the bundleType attribute
    if (product?.bundleType) {
      switch (product.bundleType) {
        case "30-mightyman":
          return `${product.quantity} Tablets Mightyman Bundle (All Strengths)`;
        case "24-alert-sleep":
          return `${product.quantity} Tablets Alert/Sleep Bundle`;
        case "34-alert-sleep-pain":
          return `${product.quantity} Tablets Alert/Sleep/Pain Bundle`;
        case "50-mightyman-var1":
          return `${product.quantity} Tablets Mightyman Bundle (Variation 1)`;
        case "50-mightyman-var2":
          return `${product.quantity} Tablets Mightyman Bundle (Variation 2)`;
        // Add other bundle types and their respective return titles here
        default:
          // A default format for bundles if none of the above match
          return `${product.quantity} Tablets ${product.name} Bundle`;
      }
    } else {
      // The existing logic for non-bundle products
      if (product?.name?.toLowerCase().includes("blue")) {
        return `${product?.name} ${product?.quantity} x 100MG MALE SEX TABLETS`;
      } else if (product?.name?.toLowerCase().includes("red")) {
        return `${product?.name} ${product?.quantity} x 200MG MALE SEX TABLETS`;
      } else if (product?.name?.toLowerCase().includes("yellow")) {
        return `${product?.name} ${product?.quantity} x 60MG MALE SEX TABLETS`;
      } else if (product?.name?.toLowerCase().includes("orange")) {
        return `${product?.name} ${product?.quantity} x 150MG MALE SEX TABLETS`;
      } else if (product?.name?.toLowerCase().includes("pink")) {
        return `${product?.name} ${product?.quantity} x 100MG WOMEN SEX TABLETS`;
      } else if (product?.name?.toLowerCase().includes("tapen")) {
        return `${product?.name} ${product?.quantity} x 100MG PAIN RELIEF TABLETS`;
      } else if (product?.name?.toLowerCase().includes("sleepiclone")) {
        return `${product?.name} (Fast Acting - Enhanced Sleep) ${product?.quantity} x 10MG`;
      } else if (product?.name?.toLowerCase().includes("energy")) {
        return `${product?.name} (Alert & Focus) ${product?.quantity} x 200MG`;
      } else if (product?.name?.toLowerCase().includes("makagra")) {
        return `${product?.name} ${product?.quantity} x ${getMG()}MG Sachets`;
      } else {
        // Ensure the default case for non-bundles doesn't append MG information
        return product?.name; // or some other appropriate default title
      }
    }
    // Default fallback if none of the above conditions are met
    return product?.name;
  };

  const productDescriptionMapping = {
    Blue: `Used for: Enhancing sexual performance, promoting intimacy.
    Discover renewed confidence and invigorate your personal moments with these tablets. Designed for those seeking to heighten their experiences, these tablets work by supporting healthy blood flow and stamina.`,
    Red: `Used for: Enhancing sexual performance, promoting intimacy.
    Discover renewed confidence and invigorate your personal moments with these tablets. Designed for those seeking to heighten their experiences, these tablets work by supporting healthy blood flow and stamina.`,
    Yellow: `Used for: Enhancing sexual performance, promoting intimacy.
    Discover renewed confidence and invigorate your personal moments with these tablets. Designed for those seeking to heighten their experiences, these tablets work by supporting healthy blood flow and stamina.`,
    Orange: `Used for: Enhancing sexual performance, promoting intimacy.
    Discover renewed confidence and invigorate your personal moments with these tablets. Designed for those seeking to heighten their experiences, these tablets work by supporting healthy blood flow and stamina.`,
    Pink: `Used for: Enhancing sexual performance, promoting intimacy.
    Discover renewed confidence and invigorate your personal moments with these tablets. Designed for those seeking to heighten their experiences, these tablets work by supporting healthy blood flow and stamina.`,
    Sample: `Used for: Enhancing sexual performance, promoting intimacy.
    Discover renewed confidence and invigorate your personal moments with these tablets. Designed for those seeking to heighten their experiences, these tablets work by supporting healthy blood flow and stamina.`,
    Sleepiclone: `Used for : Help with insomnia and sleeping issues and get the best sleep
    It is used for the short-term and symptomatic relief of sleep disturbances. Sleepiclone can help with difficulty falling asleep, frequent wake-ups during the night, or early morning awakenings.`,
    Energy: `Used for : Increase alertness, Stay awake. 
    The most popular type of "smart pill" taken by storm by students who want to stay awake and alert for longer.
    May improve some aspects of executive function such as planning, decision-making, and fluid intelligence. In contrast to the mixed results with simple tasks.`,
  };

  const bundleDescriptionMapping = {
    "30-mightyman": `This bundle includes a balanced mix of our Mightyman range:
    - 10 x 100mg Mightyman Blue: For stamina and endurance
    - 10 x 200mg Mightyman Red: For peak performance
    - 10 x 60mg Mightyman Yellow: For a quick boost`,
    "24-alert-sleep": `
    - 10 x 200mg Enerfanil: For prolonged alertness
    - 14 x 10mg Sleepiclone: For a restful sleep`,
    "34-alert-sleep-pain": `An all-in-one solution for day and night wellness:
    - 10 x 200mg Enerfanil: For mental sharpness
    - 14 x 10mg Sleepiclone: For enhanced sleep
    - 10 x 100mg Painiclone: For effective pain relief`,
    "50-mightyman-var1": `Our comprehensive Mightyman bundle for sustained performance:
    - 20 x 100mg Mightyman Blue
    - 20 x 200mg Mightyman Red
    - 10 x 60mg Mightyman Yellow`,
    "50-mightyman-var2": `A varied selection of Mightyman tablets for maximum effectiveness:
    - 20 x 200mg Mightyman Red
    - 20 x 60mg Mightyman Yellow
    - 10 x 100mg Mightyman Blue`,
  };

  const getProductDescription = () => {
    // Check if the product is a bundle and return the mapped description
    if (product?.bundleType) {
      return (
        bundleDescriptionMapping[product.bundleType] ||
        "No description available for this bundle."
      );
    }
    // Fall back to individual product descriptions if not a bundle
    if (product?.name) {
      const keywords = Object.keys(productDescriptionMapping);
      for (const keyword of keywords) {
        if (product?.name.includes(keyword)) {
          return productDescriptionMapping[keyword];
        }
      }
    }
    return "No description available."; // Default description if none is found
  };

  const productInstructionMapping = {
    Blue: "Take one tablet 30 minutes before activity. Do not exceed 1 tablet in 24 hours.",
    Red: "Take one tablet 30 minutes before activity. Do not exceed 1 tablet in 24 hours.",
    Yellow:
      "Take one tablet 30 minutes before activity. Do not exceed 1 tablet in 24 hours.",
    Pink: "Take one tablet 30 minutes before activity. Do not exceed 1 tablet in 24 hours.",
    Orange:
      "Take one tablet 30 minutes before activity. Do not exceed 1 tablet in 24 hours.",
    Sample:
      "Take one tablet 30 minutes before activity. Do not exceed 1 tablet in 24 hours.",
    Sleepiclone: "Take 1 tablet before bed (Effective within 15 minutes).",
    Energy:
      "Take 1 from the time of need - Must stay hydrated, Keep in mind will be like a caffeine boost so take with care.",
    Jelly:
      "Take 1 sachet 30 minutes before activity. Do not exceed 1 sachet in 24 hours.",
  };

  const getProductInstructions = () => {
    if (product?.name) {
      const keywords = Object.keys(productInstructionMapping);
      for (const keyword of keywords) {
        if (product?.name.includes(keyword)) {
          return productInstructionMapping[keyword];
        }
      }
    }
    return "No instructions available."; // Return a default description if none is found
  };

  return (
    <div className={classes.container}>
      {product && (
        <div
          style={{ alignSelf: "flex-start", marginLeft: "1rem" }}
          className={classes.primaryInfoContainer}
        >
          <h3>{getProductName()}</h3>

          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/shop")}
            name="name"
          >
            {product?.brand}
          </span>
        </div>
      )}

      <div className={classes.topDiv}>
        <div className={classes.imgContainer}>
          <img
            onMouseEnter={handleChangeImage}
            onMouseLeave={(e) => (e.target.src = product?.image)}
            src={product?.image}
            ref={imageRef}
            alt={product?.name}
          />
        </div>

        <div className={classes.rightDiv}>
          <div className={classes.alternateInfoContainer}>
            <h3>{getProductName()}</h3>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/shop")}
              name="name"
            >
              {product?.brand}
            </span>
            <span className={classes.alternatePrice}>£{product?.price}</span>
          </div>
          <div className={classes.payLaterContainer}>
            <div id="klarna" className={classes.klarnaContainer}>
              <Klarna className={`${classes.payLaterIcon} ${classes.klarna}`} />
              <span className={classes.payLaterText}>Pay in 3</span>
            </div>
            <div id="clearpay" className={classes.clearpayContainer}>
              <img
                src={Clearpay}
                alt="clearpay logo"
                className={`${classes.payLaterIcon} ${classes.clearpay}`}
              />
              <span className={classes.payLaterText}>Pay in 4</span>
            </div>
          </div>
          {!cartIsVisible && (
            <button
              ref={cartRef}
              onClick={addProductToCart}
              id="cartBtn"
              name="cart"
            >
              {cartText}
            </button>
          )}
        </div>
      </div>

      <div className={classes.iconsDivContainer}>
        {product && (
          <span name="price" className={classes.priceSpan}>
            £{product?.price}
          </span>
        )}
        <div className={classes.icons}>
          <div className={classes.icon}>
            <FaGlobeAmericas size={20} />
            <span>Worldwide Shipping</span>
          </div>
          <div className={classes.icon}>
            <BsTruck size={20} />
            <span>Same-day Dispatch</span>
          </div>
          <div className={classes.icon}>
            <RiSecurePaymentLine size={20} />
            <span>SSL Secure Payments</span>
          </div>
          <div className={classes.icon}>
            <img
              onLoad={() => setImageLoaded(true)}
              style={{ height: "1.5em", alignSelf: "flex-start" }}
              src={dot}
              alt="dot"
            />
            {imageLoaded && <span>In Stock</span>}
          </div>
        </div>
      </div>

      <div className={classes.infoDivContainer}>
        <div
          onClick={() => setActiveTab("directions")}
          name="directions"
          className={classes.infoDiv}
        >
          Directions
          {activeTab === "directions" && (
            <span name="answer">{getProductInstructions()}</span>
          )}
        </div>

        <div className={classes.divider}></div>
        <div
          onClick={() => setActiveTab("shipping")}
          className={classes.infoDiv}
        >
          Shipping Information
          {activeTab === "shipping" && (
            <span name="answer">
              All orders are shipped same-day if made before 11am through Royal
              Mail First Class which usually takes 1-3 working days to be
              delivered.
            </span>
          )}
        </div>
        <div className={classes.divider}></div>

        <div
          onClick={() => setActiveTab("details")}
          className={classes.infoDiv}
        >
          Product Details
          {activeTab === "details" && (
            <>
              {!product?.name.includes("Sample") && (
                <>
                  <span name="answer">
                    Quantity: {product?.quantity} Tablets
                  </span>

                  <span name="description">{getProductDescription()}</span>
                </>
              )}
              <span style={{ color: "grey" }} name="answer">
                This product is not intented to diagnose, treat or prevent
                disease. Do not take this product if you are on any medication.
                Consult with your physician or healthcare provider before using
                this product
              </span>
            </>
          )}
        </div>

        <div className={classes.divider}></div>
      </div>

      {!cartIsVisible && (
        <button
          ref={secondButtonRef}
          onClick={addProductToCart}
          id="secondCartBtn"
          name="secondCart"
          className="secondCart"
        >
          {cartText}
        </button>
      )}

      <ProductScroll products={productScrollArray} />
    </div>
  );
}
