import { Link, useNavigate } from "react-router-dom";
import classes from "./Footer.module.css";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../Context/UserContext";
import { ReactComponent as VisaSVG } from "../Assets/visa.svg";
import { ReactComponent as MastercardSVG } from "../Assets/mastercard.svg";
import { ReactComponent as StripeSVG } from "../Assets/stripe.svg";
import { ReactComponent as GooglePaySVG } from "../Assets/googlepay.svg";
import { AiFillApple } from "react-icons/ai";
import logo from "../Assets/logoTransparent.png";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { firebaseContext } from "../Context/FirebaseContext";
import { ReactComponent as Klarna } from "../Assets/klarna.svg";
import Clearpay from "../Assets/clearpay.png";

export default function Footer() {
  const { user } = useContext(UserContext);
  const { db } = useContext(firebaseContext);

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const [subscribeText, setSubscribeText] = useState("");
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    if (user) {
      checkSubscription();
      setSubscribeText(user?.email);
    }
    setEmailIsValid(validateEmail(subscribeText));
  }, [user, subscribeText]);

  const checkSubscription = async () => {
    const docRef = doc(db, "users", user?.uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists() && docSnap.data().isSubscribed === true) {
      setIsSubscribed(true);
    }
  };

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setSubscribeText(e.target.value);
    setEmailIsValid(validateEmail(e.target.value));
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    if (user) {
      await updateDoc(doc(db, "users", user?.uid), {
        isSubscribed: true,
      });
      setSubscribeText("");
    } else {
      navigate("/login");
    }
  };

  const copyrightDate = new Date().getFullYear();
  const copyrightText = `Copyright © ${copyrightDate} Just Herbal. All rights reserved.`;

  return (
    <div className={classes.container}>
      <Link to="/">
        <img
          style={{ height: "2.5em", marginTop: "2em" }}
          src={logo}
          alt="Just Herbal Logo"
        />
      </Link>

      {!isSubscribed && (
        <div className={classes.content}>
          <div className={classes.subscribeContainer}>
            <form onSubmit={handleSubmitForm} className={classes.subscribeForm}>
              <input
                value={subscribeText}
                onChange={handleInputChange}
                type="email"
                placeholder="Enter your email"
              />
              {emailIsValid && (
                <button
                  className={classes.submit}
                  onSubmit={handleSubmitForm}
                  type="submit"
                >
                  Subscribe
                </button>
              )}
            </form>
          </div>
          <div style={{ height: "2em" }} className={classes.iconsContainer}>
            <VisaSVG style={{ width: "3em", height: "100%" }} />
            <MastercardSVG style={{ width: "3em", height: "100%" }} />
            <StripeSVG style={{ width: "3em", height: "100%" }} />
            <GooglePaySVG style={{ width: "3em", height: "70%" }} />
            <AiFillApple
              style={{ width: "3em", height: "70%", color: "white" }}
            />
            <Klarna
              style={{
                width: "3em",
                height: "70%",
                backgroundColor: "#FFB3C7",
                padding: "0.3em 0.5em",
                borderRadius: "4px",
              }}
            />
            <img
              src={Clearpay}
              alt="Clearpay"
              style={{
                width: "auto",
                backgroundColor: "#B2FCE4",
                borderRadius: "4px",
                height: "70%",
              }}
            />
          </div>
        </div>
      )}

      <div style={{ marginTop: "1em" }} className={classes.emailText}>
        <a href="mailto:healthherbuk@hotmail.com">healthherbuk@hotmail.com</a>
      </div>
      <div style={{ marginTop: "1em" }} className={classes.copyrightText}>
        {copyrightText}
      </div>
    </div>
  );
}
