import classes from "./SignIn.module.css";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { CgPassword } from "react-icons/cg";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../Context/UserContext";
import { useNavigate } from "react-router-dom";

export default function SignIn({
  handleLogin,
  setErrors,
  errors,
  setShowErrors,
}) {
  const emailRef = useRef();
  const passwordRef = useRef();

  const [formDetails, setFormDetails] = useState({
    email: "",
    password: "",
  });

  const [formIsValid, setFormIsValid] = useState(false);

  const navigate = useNavigate();

  const handleErrors = () => {
    validateForm("email");
    validateForm("password");
    if (errors?.email !== "" || errors?.password !== "") {
      setShowErrors(true);
      return;
    }
  };

  const loginUser = async () => {
    const loginSuccess = await handleLogin({
      emailText: formDetails.email,
      passwordText: formDetails.password,
      navigate: () => {
        navigate("/", { replace: true });
      },
    });
  };

  const validateForm = (input) => {
    const { email, password } = formDetails;
    const refs = {
      email: emailRef,
      password: passwordRef,
    };

    const emailRegex = /^[a-zA-Z0-9._]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$(?!\.)/;

    if (
      !refs[input] ||
      !formDetails[input] ||
      formDetails[input].trim().length === 0
    ) {
      refs[input].current.style.border = "1px solid red";
      return;
    }

    if (input === "email") {
      if (!emailRegex.test(String(email).toLowerCase())) {
        refs[input].current.style.border = "1px solid red";
        setErrors({ ...errors, email: "Please enter a valid email" });
        return;
      }
    }

    if (input === "password") {
      if (password.length < 6) {
        refs[input].current.style.border = "1px solid red";
        setErrors({
          ...errors,
          password: "Password must be at least 6 characters long",
        });
        return;
      }

      refs[input].current.style.border = "1px solid #ccc";
    }

    refs[input].current.style.border = "1px solid #ccc";

    const formIsValid =
      email.trim().length > 0 &&
      password.trim().length >= 6 &&
      emailRegex.test(String(email).toLowerCase());

    setFormIsValid(formIsValid);
    if (
      email.trim().length > 0 &&
      emailRegex.test(String(email).toLowerCase())
    ) {
      setErrors({ ...errors, email: "" });
    }

    if (password.trim().length >= 6) {
      setErrors({ ...errors, password: "" });
    }
  };

  const handleChange = (e) => {
    setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
    validateForm(e.target.name);
  };

  useEffect(() => {
    if (formDetails.email.trim().length > 0) {
      validateForm("email");
    }

    if (formDetails.password.trim().length > 0) {
      validateForm("password");
    }
  }, [formDetails]);

  return (
    <form onSubmit={(e) => e.preventDefault()} className={`${classes.form}`}>
      <div ref={emailRef} className={classes.inputDiv}>
        <div
          style={{
            overflow: "hidden",
            display: "flex",
            alignItem: "center",
            paddingRight: "1em",
            borderRight: "1.5px solid #ccc",
          }}
        >
          <MdOutlineAlternateEmail size={20} />
        </div>
        <input
          onChange={handleChange}
          type="email"
          name="email"
          value={formDetails.email}
          placeholder="email@email.com"
        />
      </div>
      <div ref={passwordRef} className={classes.inputDiv}>
        <div
          style={{
            display: "flex",
            alignItem: "center",
            paddingRight: "1em",
            borderRight: "1.5px solid #ccc",
          }}
        >
          <CgPassword size={20} />
        </div>
        <input
          onChange={handleChange}
          name="password"
          type="password"
          value={formDetails.password}
          placeholder="Password"
        />
      </div>
      <button onClick={formIsValid ? loginUser : handleErrors}>Continue</button>
    </form>
  );
}
