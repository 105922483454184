import { useContext, useEffect, useState } from "react";
import classes from "./UserDash.module.css";
import { UserContext } from "../Context/UserContext";

import { TiArrowRight } from "react-icons/ti";
import UserPersonalDetails from "./UserPersonalDetails";
import UserOrders from "./UserOrders";
import { BsFillCartCheckFill } from "react-icons/bs";
import { FiBookOpen } from "react-icons/fi";
import UserAddressBook from "./UserAddressBook";
import { Link, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { firebaseContext } from "../Context/FirebaseContext";

export default function UserDash() {
  const [activeSection, setActiveSection] = useState("");
  const { user, handleLogOut, displayName } = useContext(UserContext);
  const { db } = useContext(firebaseContext);
  const [name, setName] = useState(displayName);

  const handleChangeSession = (section) => {
    setActiveSection(section === activeSection ? "" : section);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setName(user?.displayName !== null ? user?.displayName : "");
    }
  }, [user, displayName]);

  if (user?.admin) {
    navigate("/admin/orders");
  } else if (!user && name !== "") return null;
  return (
    <div className={classes.container}>
      <div className="d-flex align-item-center justify-content-between w-100">
        <h4>Profile</h4>
        <span
          onClick={() => handleLogOut({ navigate: () => navigate("/") })}
          style={{
            fontWeight: "600",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Log out
        </span>
      </div>

      <div
        className={classes.profileCard}
        onClick={() => handleChangeSession("personal")}
      >
        <div className={classes.profileCardImg}>{name?.slice(0, 1)}</div>
        <div className={classes.profileCardInfo}>
          <span>{name}</span>
          <span>My Profile</span>
        </div>
        <TiArrowRight size={25} />
      </div>

      {activeSection === "personal" && <UserPersonalDetails user={user} />}

      <div
        className={classes.profileCard}
        onClick={() => handleChangeSession("address")}
      >
        <div className={classes.profileCardImg}>
          <FiBookOpen />
        </div>
        <div className={classes.profileCardInfo}>
          <span>Address Book</span>
          <span>Shipping / Billing Address</span>
        </div>
        <TiArrowRight size={25} />
      </div>

      {activeSection === "address" && <UserAddressBook />}

      <Link to="/dashboard/orders">
        <div
          className={classes.profileCard}
          onClick={() => handleChangeSession("orders")}
        >
          <div className={classes.profileCardImg}>
            <BsFillCartCheckFill />
          </div>
          <div className={classes.profileCardInfo}>
            <span>My Orders</span>
            <span>View previous orders</span>
          </div>
          <TiArrowRight size={25} />
        </div>
      </Link>
    </div>
  );
}
