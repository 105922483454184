import { useContext } from "react";
import classes from "./CartItem.module.css";
import { CartContext } from "../Context/CartContext";
import { Link, useNavigate } from "react-router-dom";

export default function CartItem({ product }) {
  console.log("product: ", product);
  const { handleRemoveItem, handleCartDecrement, handleCartIncrement, setCartIsVisible, cartIsVisible } =
    useContext(CartContext);

  if (product) {
    return (
      <div className={classes.container}>
        <div className={classes.content}>
          <div className={classes.left}>
            <Link to={`/product/${product?.id}`}>
              <img onClick={() => setCartIsVisible(false)} src={product?.image} alt={product?.name} />
            </Link>
          </div>
          <div className={classes.center}>
            <span>{product?.name}</span>
            <span style={{ fontSize: "0.9em", fontWeight: "600" }}>{product?.quantity}</span>
            <span name="productPrice">£{(product?.price * product?.prodQuantity).toFixed(2)}</span>
          </div>
          <div className={classes.right}>
            <div className={classes.quantityContainer}>
              <div className={product?.prodQuantity !== 1 ? classes.buttonContainer : classes.deleteRed}>
                <button onClick={() => handleCartIncrement(product?.stripeID)}>+</button>
                <span>{product?.prodQuantity}</span>
                <button
                  onClick={
                    product?.prodQuantity !== 1
                      ? () => handleCartDecrement(product?.stripeID)
                      : () => handleRemoveItem(product?.stripeID)
                  }
                >
                  -
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
