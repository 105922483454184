import classes from "./OrderCard.module.css";
import { BsCheckCircleFill } from "react-icons/bs";
import { TbTruckLoading } from "react-icons/tb";
import { FcExpand } from "react-icons/fc";
import { useState } from "react";
import CartItem from "./CartItem";
import ExpandedOrderCard from "./ExpandedOrderCard";

export default function OrderCard({ order, fullOrder }) {
  console.log("order: ", order);
  const [isExpanded, setIsExpanded] = useState(false);

  const currentOrder = order[0];
  console.log("currentOrder: ", currentOrder);

  const quantity = fullOrder?.items?.reduce((acc, item) => {
    return acc + item.quantity;
  }, 0);
  // console.log("quantity: ", quantity);

  if (order) {
    return (
      <div className={classes.container} onClick={() => setIsExpanded(!isExpanded)}>
        <div className={classes.orderContent}>
          <div className="d-flex justify-content-between align-items-center">
            <span name="orderNumber">
              # {fullOrder?.customer?.orderNumber} | {quantity} {fullOrder?.items?.length === 1 ? "item" : "items"}
            </span>
            <span
              style={{
                color: fullOrder?.customer?.status === "Completed" ? "#39CA5A" : "#FF850A",
              }}
              name="status"
            >
              {fullOrder?.customer?.status === "Completed" ? (
                <BsCheckCircleFill color="#39CA5A" size={15} style={{ marginRight: "1em" }} />
              ) : (
                <TbTruckLoading color="#FF850A" size={15} style={{ marginRight: "1em", display: "block" }} />
              )}
              {fullOrder?.customer?.status === "Pending" ? "In Progress" : "Completed"}
            </span>
          </div>
          <div className="d-flex justify-content-between">
            <span name="orderDate">{fullOrder?.timestamp}</span>
            <span name="orderTotal">£{(fullOrder?.customer?.total / 100).toFixed(2)}</span>
          </div>
          <FcExpand
            style={{
              alignSelf: "center",
              position: "absolute",
              bottom: "10px",
              transform: isExpanded ? "scaleY(-1)" : "none",
            }}
          />
        </div>
        {isExpanded && (
          <div className={classes.orderDetails}>
            {fullOrder?.items.map((item) => {
              return <ExpandedOrderCard key={item?.price} item={item} />;
            })}
          </div>
        )}
      </div>
    );
  }
}
