import { initializeApp } from "firebase/app";
import { createContext, useEffect } from "react";
import { getFirestore, collection, onSnapshot } from "firebase/firestore";
import { useState } from "react";
import { getAnalytics } from "firebase/analytics";

export const firebaseContext = createContext();

export const FirebaseContextProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const db = getFirestore(app);

  const fetchProducts = useEffect(() => {
    onSnapshot(collection(db, "products"), (snapshot) => {
      setProducts(
        snapshot.docs
          .filter((doc) => doc.data().active === true)
          .map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
  }, []);

  const firebaseObject = {
    db,
    products,
    app,
  };

  return (
    <firebaseContext.Provider value={firebaseObject}>
      {children}
    </firebaseContext.Provider>
  );
};
