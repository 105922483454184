import { createRoot } from "react-dom/client";

import App from "./App";
import { FirebaseContextProvider } from "./Context/FirebaseContext";
import { UserContextProvider } from "./Context/UserContext";
import CartContextProvider from "./Context/CartContext";
import "./styles.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <>
    <FirebaseContextProvider>
      <UserContextProvider>
        <CartContextProvider>
          <App />
        </CartContextProvider>
      </UserContextProvider>
    </FirebaseContextProvider>
  </>
);
