import { createContext, useEffect, useState } from "react";

export const CartContext = createContext();

export default function CartContextProvider({ children }) {
  const [cart, setCart] = useState(() => {
    const localCart = localStorage.getItem("cart");
    return localCart ? JSON.parse(localCart) : [];
  });

  const [cartLength, setCartLength] = useState(0);
  const [cartTotal, setCartTotal] = useState(0);
  const [cartIsVisible, setCartIsVisible] = useState(false);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
    setCartLength(cart.reduce((total, item) => total + item.prodQuantity, 0));
    setCartTotal(cart.reduce((total, item) => total + item.prodQuantity * parseFloat(item.price), 0));
  }, [cart]);

  const handleAddToCart = ({ product, prodQuantity }) => {
    const newCart = [...cart];
    const itemIndex = newCart.findIndex((item) => item.stripeID === product.stripeID);

    if (itemIndex > -1) {
      newCart[itemIndex].prodQuantity += prodQuantity;
    } else {
      newCart.push({
        name: product.name,
        stripeID: product.stripeID,
        prodQuantity,
        price: product.price,
        image: product.image,
      });
    }
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const handleCartIncrement = (stripeID) => {
    const newCart = [...cart];
    const itemIndex = newCart.findIndex((item) => item.stripeID === stripeID);

    if (itemIndex > -1) {
      newCart[itemIndex].prodQuantity += 1;
    }
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const handleCartDecrement = (stripeID) => {
    const newCart = [...cart];
    const itemIndex = newCart.findIndex((item) => item.stripeID === stripeID);

    if (itemIndex > -1 && newCart[itemIndex].prodQuantity > 1) {
      newCart[itemIndex].prodQuantity -= 1;
    }
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const handleRemoveItem = (stripeID) => {
    const newCart = cart.filter((item) => item.stripeID !== stripeID);
    setCart(newCart);
    localStorage.setItem("cart", JSON.stringify(newCart));
  };

  const clearCart = () => {
    setCart([]);
    localStorage.setItem("cart", JSON.stringify([]));
  };

  useEffect(() => {
    document.body.style.overflow = cartIsVisible ? "hidden" : "unset";
  }, [cartIsVisible]);

  const stateObject = {
    clearCart,
    handleAddToCart,
    cart,
    cartLength,
    cartIsVisible,
    setCartIsVisible,
    cartTotal,
    handleCartIncrement,
    handleCartDecrement,
    handleRemoveItem,
  };

  return <CartContext.Provider value={stateObject}>{children}</CartContext.Provider>;
}
