import { MdOutlineAlternateEmail } from "react-icons/md";
import classes from "./SignIn.module.css";
import { useEffect, useRef, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { ReactComponent as Loader } from "../Assets/loader.svg";
import { useNavigate } from "react-router-dom";

export default function ResetPassword({ setActiveScreen }) {
  const emailRef = useRef();
  const [email, setEmail] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const validateForm = () => {
    if (!emailRef || !email || email?.trim().length === 0) {
      emailRef.current.style.border = "1px solid red";
      return;
    }

    const emailRegex = /^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+/;

    if (!emailRegex.test(String(email).toLowerCase())) {
      emailRef.current.style.border = "1px solid red";
      return;
    }

    emailRef.current.style.border = "1px solid #ccc";
  };

  useEffect(() => {
    if (email?.length > 0) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [email]);

  const navigate = useNavigate();

  const handleNavigateToSignIn = () => {
    setTimeout(() => {
      setActiveScreen("signin");
    }, 3000);
  };

  const handlePasswordReset = () => {
    setIsLoading(true);
    validateForm();
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setIsLoading(false);
        setIsSent(true);
        handleNavigateToSignIn();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  };

  return (
    <form onSubmit={(e) => e.preventDefault()} className={`${classes.form}`}>
      {!isSent ? (
        <>
          <div ref={emailRef} className={classes.inputDiv}>
            <div
              style={{
                overflow: "hidden",
                display: "flex",
                alignItem: "center",
                paddingRight: "1em",
                borderRight: "1.5px solid #ccc",
              }}
            >
              <MdOutlineAlternateEmail size={20} />
            </div>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              name="email"
              value={email}
              placeholder="email@email.com"
            />
          </div>

          <button
            disabled={!formIsValid}
            type="submit"
            onClick={handlePasswordReset}
          >
            {isLoading ? (
              <Loader style={{ height: "2em", width: "2em" }} />
            ) : (
              "Continue"
            )}
          </button>
        </>
      ) : (
        <div style={{ border: "none" }} className={classes.resetText}>
          Your password reset link was sent to your email. Login in again once
          you have changed your password
        </div>
      )}
    </form>
  );
}
