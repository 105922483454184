import { createContext, useState, useContext, useEffect } from "react";
import { firebaseContext } from "./FirebaseContext";
import {
  signOut,
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";

export const UserContext = createContext();

export function UserContextProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem("isLoggedIn") === "true";
  });

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")) || null);
  const [displayName, setDisplayName] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);

  const { app, db } = useContext(firebaseContext);
  const auth = getAuth(app);

  const handleLogin = async ({ emailText, passwordText, navigate }) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, emailText, passwordText);
      const user = userCredential.user;

      setIsLoggedIn(true);
      setUser(user);
      localStorage.setItem("isLoggedIn", "true");
      localStorage.setItem("user", JSON.stringify(user));

      // Check for Square ID and create Square customer and Brevo contact if needed
      await ensureSquareCustomerAndBrevoContact(user);

      // Check if the user is attempting to checkout and process the checkout
      const isCheckingOut = localStorage.getItem("isCheckingOut") === "true";
      if (isCheckingOut) {
        const checkoutData = JSON.parse(localStorage.getItem("checkoutData"));
        try {
          const response = await fetch("https://justherbal.herokuapp.com/checkout", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(checkoutData),
          });
          const checkoutResponse = await response.json();
          if (checkoutResponse.url) {
            window.location.assign(checkoutResponse.url);
          }
        } catch (err) {
          console.error("Checkout process failed:", err);
        }
      }

      navigate();
    } catch (error) {
      console.error("Login error:", error);
      switch (error.code) {
        case "auth/wrong-password":
          setError("Incorrect password, please try again.");
          break;
        case "auth/user-not-found":
          setError("User not found, please register.");
          break;
        default:
          setError("Failed to login. Please try again.");
          break;
      }
      return { error };
    }
  };

  const handleRegister = async ({ emailText, passwordText, nameText, navigate, isSubscribed = false }) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, emailText, passwordText);
      const user = userCredential.user;

      // Create Square customer and Brevo contact
      await ensureSquareCustomerAndBrevoContact(user, nameText, isSubscribed);

      // Update Firebase user profile with displayName
      await updateProfile(user, { displayName: nameText });

      setUser(user);
      setDisplayName(nameText);
      setIsLoggedIn(true);
      navigate();
    } catch (error) {
      console.error("Registration error: ", error);
      setError(error.message || "An error occurred during registration.");
      return { error };
    }
  };

  const handleLogOut = ({ navigate }) => {
    signOut(auth)
      .then(() => {
        setIsLoggedIn(false);
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("user");
        localStorage.removeItem("isCheckingOut");
        localStorage.removeItem("checkoutData");
        localStorage.removeItem("cart");
        setUser(null);
        navigate();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();

        // Fetch user document from Firestore
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);

        let userData = {};
        if (userDocSnap.exists()) {
          userData = userDocSnap.data();
        }

        // Update user data, only adding squareID if it doesn't exist
        const updatedUser = {
          ...user,
          admin: idTokenResult.claims.admin,
          squareID: userData.squareID ? userData.squareID : null, // Add squareID only if it's not already present
        };

        setUser(updatedUser);
        setIsLoggedIn(true);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("user", JSON.stringify(updatedUser));

        // Update Firestore only if the document doesn't exist (for new users)
        if (!userDocSnap.exists()) {
          await setDoc(userDocRef, {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            isSubscribed: true, // or get the subscription status appropriately
            phoneNumber: user.phoneNumber || "",
          });
        }
      } else {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("user");
        localStorage.removeItem("isCheckingOut");
        localStorage.removeItem("checkoutData");
        localStorage.removeItem("cart");
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const uid = user?.uid;

  const googleProvider = new GoogleAuthProvider();

  const googleSignIn = async ({ navigate }) => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Ensure Square customer and Brevo contact
      await ensureSquareCustomerAndBrevoContact(user, result._tokenResponse.displayName);

      navigate();
    } catch (error) {
      console.error("Google sign-in error or Square customer creation error:", error);
      setError(error.message);
    }
  };

  // Helper function to ensure Square customer and Brevo contact
  const ensureSquareCustomerAndBrevoContact = async (user, displayName = user.displayName, isSubscribed = false) => {
    try {
      const userRef = doc(db, "users", user.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        if (!userData.squareID) {
          // User exists but does not have a Square ID
          await createSquareCustomerAndBrevoContact(user.uid, displayName, user.email, isSubscribed);
        }
      } else {
        // User does not exist in Firestore, create user document and Square customer
        await createSquareCustomerAndBrevoContact(user.uid, displayName, user.email, isSubscribed);
        await setDoc(userRef, {
          uid: user.uid,
          displayName: displayName,
          email: user.email,
          isSubscribed: isSubscribed,
          phoneNumber: user.phoneNumber || "",
          squareID: data.squareID, // Add squareID here
        });
      }
    } catch (error) {
      console.error("Error ensuring Square customer and Brevo contact:", error);
      // Handle the error appropriately
    }
  };

  // Helper function to create Square Customer and Brevo contact
  async function createSquareCustomerAndBrevoContact(uid, displayName, email, isSubscribed) {
    try {
      const names = displayName.split(" ");
      const givenName = names[0];
      const familyName = names.length > 1 ? names.slice(1).join(" ") : "";

      // const response = await fetch("http://localhost:4000/create-square-customer", {
      const response = await fetch("https://justherbal.herokuapp.com/create-square-customer", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email_address: email,
          given_name: givenName,
          family_name: familyName,
          phone_number: "", // Add phone number if available
          uid: uid,
          isSubscribed: isSubscribed,
        }),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.error || "Failed to create Square customer");
      }

      // Update Firebase user document with squareID (if it exists)
      const userRef = doc(db, "users", uid);
      await setDoc(userRef, { squareID: data.customer.squareID }, { merge: true });
    } catch (error) {
      console.error("Error creating Square customer and Brevo contact:", error);
      // Handle the error appropriately
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        handleLogOut,
        isLoggedIn,
        setIsLoggedIn,
        auth,
        handleLogin,
        handleRegister,
        uid,
        googleSignIn,
        error,
        displayName,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
