import { Profiler, useContext, useEffect, useMemo, useState } from "react";
import classes from "./Home.module.css";
import ProductScroll from "./ProductScroll";
import BannerDiv from "./BannerDiv";
import { ReactComponent as ShadeSVG } from "../Assets/shapeBlur.svg";
import { firebaseContext } from "../Context/FirebaseContext";
import { useLocation, useNavigate } from "react-router-dom";
import MessageSection from "./MessageSection";
import Box from "../Assets/box.png";
import Ingredients from "../Assets/ingredients.png";
import Lab from "../Assets/lab.png";
import Customer from "../Assets/inbed.png";
import ProductCard from "./ProductCard";
import WiderImgMessageSection from "./WiderImgMessageSection";
import ProgressiveImage from "react-progressive-graceful-image";
import AddressModal from "./AddressModal";

export default function Home() {
  const [imageLoaded, setImageLoaded] = useState(false);
  const { products } = useContext(firebaseContext);

  const featuredIDs = ["dde1dc06-7a21-4715-a5ed-0baae5d22b6d"];

  const featuredFirst = products?.filter((product) => {
    return product?.name?.toLowerCase() === "mightyman sample bundle";
  });

  const featuredSecond = products?.filter((product) => {
    return (
      product?.name?.toLowerCase() === "alert/sleep sample bundle" &&
      product?.quantity === 10
    );
  });

  const featuredThird = products?.filter((product) => {
    return product?.id === "268a105a-a2a5-44dd-8b46-0e28f93ffe65";
  });
  const featuredFourth = products?.filter((product) => {
    return product?.id === "411d88b7-976a-47a5-b05b-fdd283e967a8";
  });
  const featuredFifth = products?.filter((product) => {
    return product?.id === "69bc7a00-14e9-4b88-9010-dfe5a0c14153";
  });
  const featuredSixth = products?.filter((product) => {
    return product?.id === "dde1dc06-7a21-4715-a5ed-0baae5d22b6d";
  });
  const featuredSeventh = products?.filter((product) => {
    return product?.id === "7085993d-ae53-42db-af94-cf185a09b98e";
  });

  const smallerImage =
    "https://res.cloudinary.com/ddedlygoy/image/upload/v1680300030/Only%20Herbal/hero_ia4wkg.jpg";

  const largerImage =
    "https://res.cloudinary.com/ddedlygoy/image/upload/v1684168520/updated_ubvsjw.jpg";

  const navigate = useNavigate();

  const productScrollArray = useMemo(() => {
    const featuredIDs = [
      "d87f5167-b1b8-4995-b88d-7507523884ce", // Blue 10
      "0088c218-3554-43b0-821e-d3197842ea65",
      "53a7cf44-abfd-4f92-a924-43be425ed9bd",
      "69b7cbaf-9532-4ded-8b75-9e785a143eac",
      "889960a4-8986-440e-9e1a-776111bd975e",
      "825c0e8e-de32-4ad9-8eeb-5a0d0fd411dc",
      "3c7a847f-f041-4d27-ac68-32e2e076bbc7",
      "9a71580f-64da-402c-9090-711ef301bbba",
      "ad06f237-c736-46cc-a454-873a367c7a92",
      "56fbcbc0-4d02-454e-8e5f-0511f070092b",
      "e7d97eff-14dc-4ec6-ba0d-b729c779f267",
      "34f0158c-75de-4284-8425-81b33fcfea3f",
      "e9659eda-f92e-4254-a41c-396db9a9e199",
      // "b388eca4-425a-41af-832b-d986fcd90aad",
    ];

    return featuredIDs.map((id) =>
      products?.find((product) => product?.id === id)
    );
  }, [products]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.heroContainer}>
          <picture className={classes.imgContainer}>
            <source srcSet={smallerImage} media="(max-width: 861px)" />
            <source srcSet={largerImage} media="(min-width: 862px)" />
            {/* <img
            onLoad={() => !imageLoaded && setImageLoaded(true)}
            src={smallerImage}
            alt="Herbal Supplement"
          /> */}
            <ProgressiveImage src={smallerImage}>
              {(src, imageLoaded) => (
                <img
                  className={imageLoaded ? classes.loading : classes.loaded}
                  onLoad={() => !imageLoaded && setImageLoaded(true)}
                  src={src}
                  alt="Herbal Supplements"
                  width="100%"
                  height="auto"
                />
              )}
            </ProgressiveImage>
          </picture>
          <div
            className={imageLoaded ? classes.heroBox : classes.heroBoxHidden}
          >
            <div className={imageLoaded ? classes.shade : classes.shadeHidden}>
              <ShadeSVG />
            </div>
            <div className={imageLoaded ? classes.shade : classes.shadeHidden}>
              <ShadeSVG />
            </div>
            <div
              className={
                imageLoaded ? classes.heroText : classes.heroTextHidden
              }
            >
              <span className={classes.headline}>Energy you need</span>
              <span className={classes.subHeadline}>When you need it.</span>
              <button
                onClick={() => navigate("/shop")}
                className={classes.exploreButton}
              >
                Explore Shop
              </button>
            </div>
          </div>
        </div>
        <ProductScroll products={productScrollArray} />
        <MessageSection
          img1={Box}
          img2={Ingredients}
          h1={"Discreet Delivery"}
          h2={"Natural Formula"}
          p1={"Noone will know what you ordered."}
          p2={"Potent ingredients that really work."}
        />

        <div className={classes.qualityImgContainer}>
          <img
            src="https://res.cloudinary.com/ddedlygoy/image/upload/v1684170649/bed_l1sgzs.jpg"
            alt="Feel the difference all night long"
          />
          <div className={classes.imgOverlay}></div>
          <div
            className={classes.imgText}
            style={{ top: "80%", marginBottom: "2em" }}
          >
            Feel the difference all night long
          </div>
        </div>

        <h3 name="highlyRated">Bundles & Samples</h3>
        <div style={{ alignSelf: "flex-start" }} className={classes.viewAllDiv}>
          <span onClick={() => navigate("/shop")}>View All Products</span>
        </div>

        <div className={classes.featuredContainer}>
          <ProductCard key={featuredFirst[0]?.id} product={featuredFirst[0]} />
          <ProductCard
            key={featuredSecond[0]?.id}
            product={featuredSecond[0]}
          />
          <ProductCard key={featuredThird[0]?.id} product={featuredThird[0]} />
          <ProductCard
            key={featuredFourth[0]?.id}
            product={featuredFourth[0]}
          />
          <ProductCard key={featuredFifth[0]?.id} product={featuredFifth[0]} />
          <ProductCard key={featuredSixth[0]?.id} product={featuredSixth[0]} />
          <ProductCard
            key={featuredSeventh[0]?.id}
            product={featuredSeventh[0]}
          />
        </div>

        <WiderImgMessageSection
          img1={Lab}
          img2={Customer}
          h1={"Proven Formula"}
          h2={"Satisfaction Guaranteed"}
          p1={"Formulated using state of the art technology"}
          p2={"There's a reason people keep coming back"}
        />

        <BannerDiv />
      </div>
    </>
  );
}
