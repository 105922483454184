import { useContext, useState } from "react";
import classes from "./ProductCard.module.css";
import dot from "../Assets/dot.gif";
import { CartContext } from "../Context/CartContext";
import { useNavigate } from "react-router-dom";

export default function ProductCard({ product }) {
  const [imageLoaded, setImageLoaded] = useState(false);

  const { handleAddToCart } = useContext(CartContext);

  const navigate = useNavigate();

  const getMG = () => {
    if (product?.name.includes("Blue")) {
      return "100";
    } else if (product?.name.includes("Red")) {
      return "200";
    } else if (product?.name.includes("Yellow")) {
      return "60";
    } else if (product?.name.includes("Sample")) {
      return "100";
    } else if (product?.name.includes("Energy")) {
      return "200";
    } else if (product?.name.includes("Sleepiclone")) {
      return "10";
    } else if (product?.name.includes("Orange")) {
      return "150";
    } else if (product?.name.includes("Pink")) {
      return "100";
    } else if (product?.name.includes("Tapen")) {
      return "100";
    } else {
      return "100";
    }
  };

  const getProductName = () => {
    // Check if the product is a bundle by looking for the bundleType attribute
    if (product?.bundleType) {
      switch (product.bundleType) {
        case "30-mightyman":
          return `${product.quantity} Tablets Mightyman Bundle (All Strengths)`;
        case "24-alert-sleep":
          return `${product.quantity} Tablets Alert/Sleep Bundle`;
        case "34-alert-sleep-pain":
          return `${product.quantity} Tablets Alert/Sleep/Pain Bundle`;
        case "50-mightyman-var1":
          return `${product.quantity} Tablets Mightyman Bundle (Variation 1)`;
        case "50-mightyman-var2":
          return `${product.quantity} Tablets Mightyman Bundle (Variation 2)`;
        // Add other bundle types and their respective return titles here
        default:
          // A default format for bundles if none of the above match
          return `${product.quantity} Tablets ${product.name} Bundle`;
      }
    } else {
      // The existing logic for non-bundle products
      if (product?.name?.toLowerCase().includes("blue")) {
        return `${product?.name} ${product?.quantity} x 100MG`;
      } else if (product?.name?.toLowerCase().includes("red")) {
        return `${product?.name} ${product?.quantity} x 200MG`;
      } else if (product?.name?.toLowerCase().includes("yellow")) {
        return `${product?.name} ${product?.quantity} x 60MG`;
      } else if (product?.name?.toLowerCase().includes("orange")) {
        return `${product?.name} ${product?.quantity} x 150MG`;
      } else if (product?.name?.toLowerCase().includes("pink")) {
        return `${product?.name} ${product?.quantity} x 100MG`;
      } else if (product?.name?.toLowerCase().includes("tapen")) {
        return `${product?.name} ${product?.quantity} x 100MG`;
      } else if (product?.name?.toLowerCase().includes("sleepiclone")) {
        return `${product?.name} (Fast Acting - Enhanced Sleep) ${product?.quantity} x 10MG`;
      } else if (product?.name?.toLowerCase().includes("energy")) {
        return `${product?.name} (Alert & Focus) ${product?.quantity} x 200MG`;
      } else if (product?.name?.toLowerCase().includes("makagra")) {
        return `${product?.name} ${product?.quantity} x 100MG Sachets`;
      } else {
        // If the product is not a bundle and does not match any other conditions, return the product name
        return product?.name;
      }
    }
  };

  if (product) {
    return (
      <div
        onClick={() => navigate(`/product/${product.id}`)}
        className={classes.productCard}
      >
        <div className={classes.productImage}>
          <img src={product?.image} alt="product" />
        </div>
        <div className={classes.productDetails}>
          <div className={classes.productName}>
            <span>{getProductName()}</span>
          </div>
          <div className={classes.productPrice}>
            <span>£{product.price}</span>
          </div>
          <div className={classes.productPrice}>
            <span>
              {product?.name?.toLowerCase().includes("alert/sleep")
                ? 4
                : product.quantity}{" "}
              {product?.name?.toLowerCase().includes("jelly")
                ? "Sachets"
                : "Tablets"}
            </span>
          </div>
          <div className={classes.icon}>
            <img onLoad={() => setImageLoaded(true)} src={dot} alt="dot" />
            {imageLoaded && <span>In Stock</span>}
          </div>

          {(product.id === "4089f918-8185-4e4a-8a88-d6544e534b88" ||
            product.id === "9df653c8-869e-4497-a8d6-0ec0888ee976") && (
            <span className={classes.discount}>50% OFF</span>
          )}

          <button
            onClick={(e) => {
              e.stopPropagation();
              handleAddToCart({ product, prodQuantity: 1 });
            }}
          >
            Add to Cart
          </button>
        </div>
      </div>
    );
  }
}
