import { useState } from "react";
import classes from "./NotificationBar.module.css";
import { IoIosClose } from "react-icons/io";

export default function NotificationBar() {
  const [showNotification, setShowNotification] = useState(true);

  if (showNotification) {
    return (
      <div className={classes.container}>
        <div className={classes.notificationContent}>
          <span>Free First Class Delivery On All Orders</span>
          <div
            onClick={(showNotification) =>
              setShowNotification(!showNotification)
            }
            className={classes.x}
          >
            <IoIosClose size={28} />
          </div>
        </div>
      </div>
    );
  }
}
