import classes from "./Header.module.css";

import { CgShoppingBag } from "react-icons/cg";
import { useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CgProfile } from "react-icons/cg";
import { UserContext } from "../Context/UserContext";
import { CartContext } from "../Context/CartContext";
import { ReactComponent as Shop } from "../Assets/shop.svg";
import logo from "../Assets/logoTransparent.png";

export default function Header() {
  const navigate = useNavigate();
  const { isLoggedIn, user } = useContext(UserContext);
  const { cartLength, cartIsVisible, setCartIsVisible } =
    useContext(CartContext);

  const { pathname } = useLocation();

  return (
    <div className={classes.container}>
      <div className={classes.leftDiv}>
        <Shop
          style={{
            height: "1.8em",
            transform: "scaleX(-1)",
            width: "2em",
            cursor: "pointer",
          }}
          onClick={() => navigate("/shop")}
          size={25}
        />
      </div>
      <Link className={classes.logo} to="/">
        <img style={{ height: "1.7em" }} src={logo} alt="Just Herbal Logo" />
      </Link>
      <div className={classes.rightDiv}>
        <div className={classes.basketDiv}>
          <CgShoppingBag
            onClick={() => setCartIsVisible(!cartIsVisible)}
            size={20}
          />
          {cartLength !== 0 && (
            <div className={classes.cartLength}>{cartLength}</div>
          )}
        </div>
        <Link
          to={
            user?.admin ? "/admin/orders" : isLoggedIn ? "/dashboard" : "/login"
          }
          className={classes.profileDiv}
        >
          <CgProfile size={20} />
        </Link>
      </div>
    </div>
  );
}
