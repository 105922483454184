import { useContext, useEffect, useState } from "react";
import classes from "./AdminOrdersAll.module.css";
import { firebaseContext } from "../Context/FirebaseContext";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import TransactionCard from "./TransactionCard";
import { AiOutlineSearch } from "react-icons/ai";
import { Switch } from "@mui/material";
import { useLocation } from "react-router-dom";

export default function AdminOrdersAll() {
  const [orders, setOrders] = useState([]);
  const { db } = useContext(firebaseContext);

  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [placeholderText, setPlaceholderText] = useState(
    "Search by order number"
  );

  const ordersRef = collection(db, "orders");
  const q = query(ordersRef, orderBy("timestamp", "desc"), limit(250));

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setOrders((prev) => [...prev, doc.data()]);
      });
    };
    fetchData();
  }, []);

  const markChecked = (action, order) => {
    if (action === "add") {
      setSelected((prev) => [...prev, order]);
    } else {
      setSelected((prev) => prev.filter((item) => item !== order));
    }
  };

  const checkAllOrders = () => {
    setSelected(!checked ? pendingOrders.map((order) => order?.id) : []);
    setChecked(!checked);
  };

  const openSnackbar = () => {
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  };

  useEffect(() => {
    setFilteredOrders(orders.sort((a, b) => b?.timestamp - a?.timestamp));
  }, [orders]);

  const handleSearch = () => {
    if (searchText === "") {
      setFilteredOrders(orders);
      return;
    }

    const queryType =
      placeholderText === "Search by order number" ? "id" : "customer.name";

    const titleCase = (str) => {
      return str
        .toLowerCase()
        .replace(/(^|\s)\w/g, (match) => match.toUpperCase());
    };

    const queryInput =
      placeholderText === "Search by order number"
        ? searchText.toUpperCase()
        : titleCase(searchText);

    const searchQ = query(
      collection(db, "orders"),
      where(queryType, "==", queryInput)
    );
    const unsubscribe = onSnapshot(searchQ, (querySnapshot) => {
      const orders = [];
      querySnapshot.forEach((doc) => {
        orders.push(doc.data());
      });
      setFilteredOrders(orders);
    });

    return unsubscribe;
  };

  useEffect(() => {
    if (searchText === "") {
      setFilteredOrders(orders);
    }
  }, [searchText]);

  const handleSwitchChange = () => {
    if (placeholderText === "Search by order number") {
      setPlaceholderText("Search by name");
    } else {
      setPlaceholderText("Search by order number");
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.searchContainer}>
        <h4>All Transactions</h4>
        <div className={classes.search}>
          <Switch
            onChange={() => {
              handleSwitchChange();
              setSearchText("");
            }}
            size="small"
            color="warning"
            defaultChecked
          />
          <input
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder={placeholderText}
          />
          <div onClick={handleSearch} className={classes.searchIcon}>
            <AiOutlineSearch
              size={23}
              color="grey"
              style={{ display: "block" }}
            />
          </div>
        </div>
      </div>

      <div className={classes.body}>
        {" "}
        {filteredOrders
          ?.sort((a, b) => b?.timestamp - a?.timestamp)
          .map((order) => (
            <TransactionCard
              openSnackbar={openSnackbar}
              allChecked={checked}
              markChecked={markChecked}
              key={order?.customer?.orderNumber}
              order={order}
            />
          ))}
      </div>
    </div>
  );
}
