import { useState, useRef } from "react";
import classes from "./ProductCarouselItem.module.css";
import { Link } from "react-router-dom";

export default function ProductCarouselItem({ product }) {
  const handleChangeImage = () => {
    if (product?.imageSecondary !== "") {
      imageRef.current.src = product.imageSecondary;
    }
  };

  const imageRef = useRef();

  if (product) {
    return (
      <Link to={`/product/${product.id}`} className={classes.container}>
        <div className={classes.imgContainer}>
          <img
            ref={imageRef}
            className={classes.transition}
            onMouseEnter={handleChangeImage}
            onMouseLeave={(e) => (e.target.src = product.image)}
            src={product.image}
            alt={product.name}
          />
        </div>
        <div className={classes.priceDiv}>
          <span>
            {product.quantity} For £{product.price}
          </span>
        </div>
      </Link>
    );
  }
}
