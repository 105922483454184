import { useContext, useEffect, useState } from "react";
import classes from "./AdminDiscount.module.css";
import { firebaseContext } from "../Context/FirebaseContext";
import { collection, onSnapshot } from "firebase/firestore";
import { Link } from "react-router-dom";

export default function AdminDiscount() {
  const [coupons, setCoupons] = useState([]);
  const [newCouponName, setNewCouponName] = useState("");
  const [newCouponPercentage, setNewCouponPercentage] = useState("");
  const { db } = useContext(firebaseContext);

  useEffect(() => {
    const couponRef = collection(db, "coupons");
    const unsubscribe = onSnapshot(couponRef, (snapshot) => {
      const couponsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCoupons(couponsData);
      console.log("Fetched coupons:", couponsData); // Log fetched coupons
    });

    return () => {
      unsubscribe();
      console.log("Unsubscribed from coupons collection changes");
    };
  }, [db]);

  const handleCreateDiscount = async () => {
    console.log("Creating discount with:", newCouponName, newCouponPercentage); // Log attempt to create discount
    // const response = await fetch("http://localhost:4000/create-coupon", {
    const response = await fetch("https://justherbal.herokuapp.com/create-coupon", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: newCouponName,
        percentage: newCouponPercentage,
      }),
    });
    const data = await response.json();
    console.log("Created discount response:", data); // Log server response
  };

  const handleDelete = async (id) => {
    console.log("Deleting coupon with ID:", id); // Log attempt to delete coupon
    // await fetch(`http://localhost:4000/delete-coupon`, {
    await fetch(`https://justherbal.herokuapp.com/delete-coupon`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    })
      .then((res) => res.json())
      .then((data) => console.log("Delete response:", data)) // Log delete response
      .catch((err) => console.log("Error deleting coupon:", err)); // Log errors
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h3>Promotions</h3>
        <input
          type="text"
          placeholder="Coupon Name"
          value={newCouponName}
          onChange={(e) => setNewCouponName(e.target.value)}
        />
        <input
          type="number"
          placeholder="Discount Percentage"
          value={newCouponPercentage}
          onChange={(e) => setNewCouponPercentage(e.target.value)}
        />
        <button onClick={handleCreateDiscount}>Create Promo</button>
      </div>

      <div className={classes.coupons}>
        {coupons.map((coupon) => (
          <div key={coupon.id} className={classes.coupon}>
            <h4>{coupon.name}</h4>
            <span>{`${coupon.percentage}% OFF`}</span>
            <button onClick={() => handleDelete(coupon.id)} className={classes.btn}>
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}
