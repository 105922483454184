import { useContext } from "react";
import ProductCarouselItem from "./ProductCarouselItem";
import classes from "./ProductScroll.module.css";
import { firebaseContext } from "../Context/FirebaseContext";
import { useNavigate } from "react-router-dom";

export default function ProductScroll({ products }) {
  const navigate = useNavigate();

  if (products) {
    return (
      <div className={classes.divContainer}>
        <span name="title">People are talking about...</span>
        <div className={classes.viewAllDiv}>
          <span onClick={() => navigate("/shop")}>View All Products</span>
        </div>
        <div className={`${classes.container} ${classes.snap}`}>
          {products?.map((product) => (
            <ProductCarouselItem key={Math.random()} product={product} />
          ))}
        </div>
      </div>
    );
  }
}
