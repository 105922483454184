import { useState } from "react";
import classes from "./AdminCreateCampaign.module.css";
import { useNavigate } from "react-router-dom";
import { BsXSquare } from "react-icons/bs";

export default function AdminDiscount() {
  const [formDetails, setFormDetails] = useState({
    name: "",
    type: "percentage",
    amount: "",
  });

  const navigate = useNavigate();

  const handleCoupon = async (e) => {
    e.preventDefault();

    const { name, type, amount } = formDetails;

    await fetch("https://justherbal.herokuapp.com/create-coupon", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, type, amount }),
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });

    navigate("/admin/discount");
  };

  return (
    <div className={classes.container}>
      <div className="d-flex align-items-center justify-content-between my-3">
        <span style={{ margin: "0", fontSize: "1.3em", fontWeight: "600" }}>
          Create a coupon
        </span>
        <BsXSquare
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
          size={20}
          color="grey"
        />
      </div>

      <form onSubmit={handleCoupon} className={classes.form}>
        <label htmlFor="name">Coupon Code</label>
        <input
          onChange={(e) =>
            setFormDetails({
              ...formDetails,
              name: e.target.value.toUpperCase(),
            })
          }
          id="name"
          type="text"
          required
        />
        <label htmlFor="type">Type</label>
        <select
          onChange={(e) =>
            setFormDetails({ ...formDetails, type: e.target.value })
          }
          id="type"
        >
          <option value="percentage">Percentage Discount</option>
          <option value="fixed">Fixed Discount</option>
        </select>

        <label htmlFor="amount">Amount</label>
        <input
          onChange={(e) =>
            setFormDetails({ ...formDetails, amount: e.target.value })
          }
          id="amount"
          type="number"
          required
        />
      </form>
      <button onClick={handleCoupon} className={classes.button}>
        Create Coupon
      </button>
    </div>
  );
}
