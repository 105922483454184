import { useLocation, useNavigate } from "react-router-dom";
import classes from "./AdminOrderDetails.module.css";
import { BsArrowLeftSquare, BsThreeDots } from "react-icons/bs";
import { useContext, useEffect, useMemo, useState } from "react";
import { firebaseContext } from "../Context/FirebaseContext";
import OrderDetailsItem from "./OrderDetailsItem";
import { IoIosClose } from "react-icons/io";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";

export default function AdminOrderDetails() {
  const { db, products } = useContext(firebaseContext);
  const { pathname } = useLocation();
  const orderNumber = pathname.slice(14);
  const [showActions, setShowActions] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [order, setOrder] = useState(null);
  const [fullDetails, setFullDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const orderRef = useMemo(() => doc(db, "orders", orderNumber), [db, orderNumber]);

  useEffect(() => {
    const getOrder = async () => {
      setIsLoading(true);

      try {
        const docSnap = await getDoc(orderRef);
        if (docSnap.exists()) {
          const orderData = docSnap.data();
          setOrder(orderData);

          const orderProducts = await Promise.all(
            orderData.items.map(async (item) => {
              const product = products.find((p) => p.stripeID === item.prodID);
              // If product is not found, you might want to handle it (e.g., display a placeholder)
              return { ...item, product };
            })
          );

          setFullDetails(orderProducts);
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error getting document:", error);
      } finally {
        setIsLoading(false);
      }
    };

    getOrder();
  }, [orderRef, products]);

  const orderDate = order?.timestamp
    ? new Date(order.timestamp.replace(/(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/, "$3-$2-$1T$4:$5:$6"))
    : null;

  const totalOrderAmount = useMemo(() => {
    return fullDetails
      .reduce((acc, item) => {
        const itemPrice = parseFloat(item?.product?.price); // Use the decimal price from the product object
        const itemTotal = itemPrice * item?.quantity;
        return acc + itemTotal;
      }, 0)
      .toFixed(2); // Format to two decimal places for precision
  }, [fullDetails]);

  const navigate = useNavigate();

  const handleRefundOrder = async () => {
    await fetch("https://justherbal.herokuapp.com/refund", {
      // await fetch("http://localhost:4000/refund", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderID: order.id,
        paymentID: order.paymentID, // Use the correct paymentID from orderData
        userID: order?.customer?.userID,
      }),
    });
    setOpenSnackbar(true);
  };

  const handleActionClick = async (action) => {
    if (action === "fulfil") {
      const orderRef = doc(db, "orders", order?.id);
      const isUserId = order?.customer?.customerID !== "";
      const orderRefData = await getDoc(orderRef);
      console.log("orderRefData: ", orderRefData);
      const usersRef = collection(db, "users");

      const q = query(usersRef, where("squareID", "==", orderRefData.data()?.customer?.customerID));
      let docUserID = "";
      console.log("docUserID: ", docUserID);

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        docUserID = doc.id;
      });

      console.log("order", order);

      if (isUserId) {
        const customerOrderRef = doc(db, "users", docUserID, "orders", order?.id);
        await updateDoc(customerOrderRef, {
          "customer.status": "Completed",
        });
      }

      await updateDoc(orderRef, {
        "customer.status": "Completed",
      });

      navigate(-1);
    }

    if (action === "refund") {
      handleRefundOrder();
      navigate(-1);
    }
  };

  const handleDotsClick = () => {
    setShowActions(!showActions);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className="d-flex justify-content-between">
          <BsArrowLeftSquare onClick={() => navigate(-1)} size={25} style={{ cursor: "pointer" }} color="#c4c4c4" />
          <BsThreeDots
            onClick={handleDotsClick}
            className={classes.dots}
            size={25}
            style={{ cursor: "pointer" }}
            color="grey"
          />
          {showActions && (
            <div className={classes.actionsContainer}>
              <div onClick={() => handleActionClick("fulfil")} className={classes.action}>
                Mark Completed
              </div>
              <div onClick={() => handleActionClick("refund")} className={classes.action}>
                Refund Order
              </div>
              <IoIosClose
                onClick={() => setShowActions(false)}
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "5px",
                  marginRight: "0.5em",
                }}
                size={25}
              />
            </div>
          )}
        </div>
        <div className={classes.headerBottom}>
          <div className="d-flex justify-content-between">
            <span className={classes.orderNumber}>Order #{orderNumber}</span>
            <span
              style={{
                marginLeft: "auto",
                fontWeight: "700",
                color: "black",
                fontSize: "0.9em",
              }}
            >
              Total: £{totalOrderAmount}
            </span>
          </div>
          {/* Ensure orderDate is a valid date object */}
          {orderDate && (
            <div className={classes.orderDate}>
              <span>{orderDate.toLocaleDateString()}</span>
              <span>{orderDate.toLocaleTimeString().slice(0, 5)}</span>
              <span className={`${classes.chip}`}>
                <div className={classes.orderDot}></div>
                <span name="status">{order?.customer?.status}</span>
              </span>
            </div>
          )}
        </div>

        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div className={classes.orderDetails}>
            {fullDetails.map((item) => (
              <OrderDetailsItem key={item?.price} order={item} item={item?.product} />
            ))}
          </div>
        )}

        <div className={classes.orderDetailsBottom}>
          <div className={classes.orderDetailsLeft}>
            <span className={classes.orderDetailsLeftTitle}>Customer Details</span>
            <div className={classes.orderDetailsBottomLeftContent}>
              <span>{order?.customer?.name}</span>
              <span>{order?.customer?.email}</span>
              <span>{order?.customer?.phone}</span>
            </div>
          </div>
          <div className={classes.orderDetailsRight}>
            <span className={classes.orderDetailsRightTitle}>Shipping Details</span>
            <div className={classes.orderDetailsBottomRightContent}>
              <span>{order?.customer?.name}</span>
              <span>{order?.customer?.address?.address.line1}</span>
              <span>{order?.customer?.address?.address.line2}</span>
              <span>{order?.customer?.address?.address.postalCode}</span>
              <span>{order?.customer?.address?.address.city}</span>
              <span>{order?.customer?.address?.address.country}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
