import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { BsTrash } from "react-icons/bs";

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isFocused) {
    return "#2196f3";
  }
  return "grey";
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.6em;
  border-width: 1px;
  border-radius: 4px;
  height: 9em;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  color: black;
  font-size: 0.8rem;
  width: 100%;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

function DropBox({ onDrop, setImages, images }) {
  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    open,
    isDragAccept,
    isFocused,
    isDragReject,
  } = useDropzone({
    accept: "image/*",
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const dropBoxStyles = {
    display: "flex",
    textAlign: "center",
    width: "100%",
    height: "auto",
    position: "relative",
    gap: "0.5em",
  };

  const btnStyles = {
    padding: "0.3em 1em",
    backgroundColor: "#F0F5FD",
    color: "#0D356C",
    fontSize: "0.7rem",
    fontWeight: "500",
    borderRadius: "4px",
    border: "none",
    cursor: "pointer",
  };

  return (
    <>
      <section style={dropBoxStyles}>
        {images.length < 2 ? (
          images.length === 0 ? (
            <Container
              {...getRootProps({ isDragAccept, isFocused, isDragReject })}
            >
              <input {...getInputProps()} />
              <div className="d-flex align-items-center justify-content-between">
                <button type="button" style={btnStyles} onClick={open}>
                  Add Primary Image
                </button>
              </div>
            </Container>
          ) : (
            <>
              <Container
                {...getRootProps({ isDragAccept, isFocused, isDragReject })}
              >
                <input {...getInputProps()} />
                <div className="d-flex align-items-center justify-content-between flex-column">
                  <button type="button" style={btnStyles} onClick={open}>
                    Add Secondary Image
                  </button>
                </div>
              </Container>
            </>
          )
        ) : null}
      </section>
      <aside className="d-flex justify-content-end my-1"></aside>
    </>
  );
}
export default DropBox;
