import classes from "./WiderImgMessageSection.module.css";

export default function WiderImgMessageSection({
  img1,
  img2,
  h1,
  h2,
  p1,
  p2,
  img1Style,
  img1ContainerStyle,
  img2Style,
  img2ContainerStyle,
}) {
  return (
    <div className={classes.container}>
      <div
        style={img1ContainerStyle ? img1ContainerStyle : null}
        className={classes.messageContainerItem}
      >
        <div className={classes.imgContainer}>
          <img src={img1} alt="Box" style={img1Style ? img1Style : null} />
        </div>
        <div className={classes.textDiv}>
          <span style={{ fontWeight: "600" }}>{h1}</span>
          <span
            style={{ fontSize: "0.7em", marginBottom: "1em", color: "grey" }}
          >
            {p1}
          </span>
        </div>
      </div>
      <div
        style={img2ContainerStyle ? img2ContainerStyle : null}
        className={classes.messageContainerItem}
      >
        <div className={classes.imgContainer}>
          <img name="ingredients" src={img2} alt="Box" />
        </div>
        <div className={classes.textDiv}>
          <span style={{ fontWeight: "600" }}>{h2}</span>
          <span
            style={{ fontSize: "0.8em", marginBottom: "1em", color: "grey" }}
          >
            {p2}
          </span>
        </div>
      </div>
    </div>
  );
}
