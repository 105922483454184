import { BsXSquare } from "react-icons/bs";
import classes from "./AdminEditProduct.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { firebaseContext } from "../Context/FirebaseContext";
import DropBox from "./Dropbox";
import ShowImage from "./ShowImg";
import { v4 as uuid } from "uuid";

export default function AdminEditProduct() {
  const { db, products } = useContext(firebaseContext);
  const [images, setImages] = useState([]);
  console.log("images: ", images);
  console.log("images: ", images.length);
  const [formDetails, setFormDetails] = useState({
    name: "",
    quantity: "",
    price: "",
    brand: "",
    id: "",
    stripeID: "",
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [initialPrimaryUrl, setInitialPrimaryUrl] = useState("");
  const [initialSecondaryUrl, setInitialSecondaryUrl] = useState("");
  const [imagesHaveChanged, setImagesHaveChanged] = useState(false);
  const [initialImageLength, setInitialImageLength] = useState(0);

  const { pathname } = useLocation();
  const productId = pathname.split("/")[3];
  const product = products.find((product) => product.id === productId);

  useEffect(() => {
    if (product) {
      setFormDetails({
        name: product?.name,
        quantity: product?.quantity,
        price: product?.price,
        brand: product?.brand,
        id: product?.id,
        stripeID: product?.stripeID,
        image: product?.image,
        imageSecondary: product?.imageSecondary,
      });
      if (product?.imageSecondary !== "") {
        setImages([{ src: product?.image }, { src: product?.imageSecondary }]);
      } else {
        setImages([{ src: product?.image }]);
      }
      setInitialPrimaryUrl(product?.image);
      setInitialSecondaryUrl(product?.imageSecondary);
      setInitialImageLength(product?.imageSecondary ? 2 : 1);
    }
  }, [product]);

  useEffect(() => {
    const imagesHaveChanged =
      images.length === 2
        ? images[0].src !== initialPrimaryUrl ||
          images[1].src !== initialSecondaryUrl
        : images.length === 1 && images[0].src !== initialPrimaryUrl;

    const lengthHasChanged = images.length !== initialImageLength;
    setImagesHaveChanged(imagesHaveChanged || lengthHasChanged);
  }, [images, initialPrimaryUrl, initialSecondaryUrl, initialImageLength]);

  const uploadImage = async () => {
    const [firstImage, secondImage] = images.map((image) => image?.src);

    const formData1 = new FormData();
    formData1.append("file", firstImage);
    formData1.append("upload_preset", preset_key);

    const response1 = await fetch(
      `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
      {
        method: "POST",
        body: formData1,
      }
    );
    const primaryUrl = (await response1.json()).url;

    let secondaryUrl;

    if (secondImage) {
      const formData2 = new FormData();
      formData2.append("file", secondImage);
      formData2.append("upload_preset", preset_key);

      const response2 = await fetch(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        {
          method: "POST",
          body: formData2,
        }
      );
      secondaryUrl = (await response2.json()).url;
    }

    return { primaryUrl, secondaryUrl };
  };

  const preset_key = process.env.REACT_APP_CLOUDINARY_PRESET_KEY;
  const cloud_name = process.env.REACT_APP_CLOUD_NAME;

  const handleEditProduct = async (e) => {
    const primaryUrl = initialPrimaryUrl;
    const secondaryUrl = initialSecondaryUrl;

    if (imagesHaveChanged) {
      if (images.length === 1) {
        const { primaryUrl } = await uploadImage();

        const product = {
          name: formDetails?.name,
          description: formDetails?.quantity,
          images: [primaryUrl],
          primaryImg: primaryUrl,
          secondaryImg: "",
          active: true,
          price: formDetails?.price,
          id: formDetails?.id,
          stripeID: formDetails?.stripeID,
          quantity: formDetails?.quantity,
        };

        const response = await fetch(
          "https://justherbal.herokuapp.com/edit-product",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ product }),
          }
        );

        navigate("/admin/products");
      } else {
        const { primaryUrl, secondaryUrl } = await uploadImage();

        const product = {
          name: formDetails?.name,
          description: formDetails?.quantity,
          images: [primaryUrl, secondaryUrl],
          primaryImg: primaryUrl,
          secondaryImg: secondaryUrl,
          active: true,
          price: formDetails?.price,
          id: formDetails?.id,
          stripeID: formDetails?.stripeID,
          quantity: formDetails?.quantity,
        };

        const response = await fetch(
          "https://justherbal.herokuapp.com/edit-product",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ product }),
          }
        );

        navigate("/admin/products");
      }
    } else {
      const product = {
        name: formDetails?.name,
        description: formDetails?.quantity,
        images: [primaryUrl, secondaryUrl],
        primaryImg: primaryUrl,
        secondaryImg: secondaryUrl,
        active: true,
        price: formDetails?.price,
        id: formDetails?.id,
        stripeID: formDetails?.stripeID,
        quantity: formDetails?.quantity,
      };

      const response = await fetch(
        "https://justherbal.herokuapp.com/edit-product",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ product }),
        }
      );
    }

    navigate("/admin/products");
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImages((prevState) => [
          ...prevState,
          { id: uuid(), src: e.target.result },
        ]);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  useEffect(() => {
    setFormIsValid(
      [
        images.length,
        formDetails.name.length,
        formDetails.quantity,
        formDetails.price,
      ].every((value) => value > 0)
    );
  }, [images, formDetails]);

  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <form className={classes.productDetailsContainer}>
        <div
          style={{ marginBottom: "2em" }}
          className="d-flex align-items-center justify-content-between"
        >
          <span
            style={{
              margin: "0",
              fontSize: "1.3em",
              fontWeight: "600",
            }}
          >
            Edit Product
          </span>
          <BsXSquare
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            size={20}
            color="grey"
          />
        </div>

        <label htmlFor="name">Product Name</label>
        <input
          autocorrect="off"
          value={formDetails.name}
          id="name"
          onChange={(e) => {
            setFormDetails({ ...formDetails, name: e.target.value });
          }}
          type="text"
          required
        />
        <label htmlFor="quantity">Pack Size</label>
        <input
          id="quantity"
          value={formDetails.quantity}
          onChange={(e) => {
            setFormDetails({ ...formDetails, quantity: e.target.value });
          }}
          type="number"
          required
        />

        <div className={classes.productPricing}>
          <label htmlFor="price">Price</label>
          <input
            id="price"
            value={formDetails.price}
            onChange={(e) => {
              setFormDetails({ ...formDetails, price: e.target.value });
            }}
            type="number"
            required
          />
        </div>

        <DropBox images={images} setImages={setImages} onDrop={onDrop} />
        <ShowImage isEditing={true} images={images} setImages={setImages} />
      </form>

      <div className={classes.footer}>
        <button
          style={{
            backgroundColor: `${
              formIsValid ? " rgb(116, 216, 255)" : "#F2F2F2."
            }`,
            color: `${formIsValid ? "#FFF" : "#D3D3D3"}`,
            fontWeight: `${formIsValid ? "bold" : "normal"}`,
          }}
          disabled={!formIsValid}
          className={classes.footerButton}
          onClick={handleEditProduct}
        >
          Save Changes
        </button>
      </div>
    </div>
  );
}
