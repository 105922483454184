import React from "react";
function Image({ image }) {
  const imgStyles = {
    height: "100px",
    width: "auto",
    marginRight: "15px",
  };

  return (
    <div>
      <img style={imgStyles} alt="" src={image.src} />
    </div>
  );
}
export default Image;
