import { Outlet, useLocation } from "react-router-dom";
import NotificationBar from "../Components/NotificationBar";
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import { useContext, useEffect, useState } from "react";
import Cart from "../Components/Cart";
import { CartContext } from "../Context/CartContext";
import AddressModal from "../Components/AddressModal";

export default function RootLayout() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { cartIsVisible } = useContext(CartContext);

  const [showAddressModal, setShowAddressModal] = useState(true);

  const handleSendAddress = () => {
    localStorage.setItem("addressPromo", true);
    setTimeout(() => {
      setShowAddressModal(false);
    }, 1000);
  };

  useEffect(() => {
    const hasSubmitted = localStorage.getItem("addressPromo");
    if (pathname === "/" && !hasSubmitted) {
      setShowAddressModal(true);
    } else {
      setShowAddressModal(false);
    }
  }, [pathname]);

  return (
    <div id="rootLayoutContainer" style={{ scrollBehavior: "instant" }}>
      {/* {showAddressModal && (
        <AddressModal
          showAddressModal={showAddressModal}
          setShowAddressModal={setShowAddressModal}
          onClose={handleSendAddress}
        />
      )} */}
      {/* <ScrollToTop /> */}
      <NotificationBar />
      <Header />
      {cartIsVisible && <Cart />}
      <Outlet />
      {pathname !== "/dashboard" && pathname !== "/dashboard/orders" && <Footer />}
    </div>
  );
}
