import { useContext, useEffect, useState } from "react";
import classes from "./Cart.module.css";
import { UserContext } from "../Context/UserContext";
import { CartContext } from "../Context/CartContext";
import CartItem from "./CartItem";
import { IoIosCloseCircle } from "react-icons/io";
import { ReactComponent as Loader } from "../Assets/loader.svg";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { firebaseContext } from "../Context/FirebaseContext";

export default function Cart() {
  const { user } = useContext(UserContext);
  const { setCartIsVisible, cart, cartLength, handleCheckout, cartIsVisible, cartTotal, clearCart } =
    useContext(CartContext);
  const { db } = useContext(firebaseContext);
  const [isLoading, setIsLoading] = useState(false);
  const [squareCustomerID, setSquareCustomerID] = useState("");

  useEffect(() => {
    const getUserSquareID = async () => {
      if (!user) return;
      const docRef = doc(db, "users", user?.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap?.exists()) {
        setSquareCustomerID(docSnap?.data()?.squareID);
      }
    };
    getUserSquareID();
  }, [user, db]);

  const navigate = useNavigate();
  const handleNavigate = () => setCartIsVisible(false);

  const handleSquareCheckout = async () => {
    if (!user) {
      localStorage.setItem("isCheckingOut", true);
      localStorage.setItem("checkoutData", JSON.stringify(cart));
      navigate("/login");
      return;
    }

    setIsLoading(true);
    try {
      // const response = await fetch("http://localhost:4000/checkout", {
      const response = await fetch("https://justherbal.herokuapp.com/checkout", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cart: cart.map((item) => ({
            name: item.name,
            quantity: item.prodQuantity,
            price: item.price,
            prodID: item.stripeID,
          })),
          name: user.displayName,
          email: user.email,
          phoneNumber: user.phoneNumber,
        }),
      });
      const resData = await response.json();
      if (resData.url) {
        window.location.href = resData.url; // Redirect to Square payment page
        clearCart();
      }
    } catch (error) {
      console.error("Failed to initiate Square checkout:", error);
    }
    setIsLoading(false);
  };

  return (
    <div className={classes.overlay}>
      <div className={classes.container}>
        <div className={classes.header}>
          <IoIosCloseCircle
            color="#C0C0C0"
            onClick={() => setCartIsVisible(false)}
            style={{ position: "absolute", right: "22px" }}
            size={35}
          />
          <div className={classes.headerText}>
            <span style={{ margin: "0" }}>{user ? `${user?.displayName?.split(" ")[0]}'s Cart` : "Cart"}</span>
            {cartLength !== 0 && (
              <span style={{ color: "#39ca5a" }} name="freeShipping">
                You are eligible for free shipping!
              </span>
            )}
          </div>
        </div>
        <div className={classes.body}>
          {cart?.length !== 0 &&
            cart?.map((item) => {
              return <CartItem key={Math.random()} product={item} />;
            })}
          {cart?.length === 0 && <span className={classes.cartEmptyText}>Your cart is empty</span>}
        </div>
        <div className={classes.cartFooter}>
          <div className={classes.subTotal}>
            <div className={classes.divider}></div>
            <span name="subtotal">£{cartTotal.toFixed(2)}</span>
            <div className={classes.divider}></div>
          </div>
          <button onClick={cart?.length === 0 ? handleNavigate : () => handleSquareCheckout()} name="continue">
            {cart?.length !== 0 ? (
              isLoading ? (
                <Loader style={{ height: "2em", width: "2em" }} />
              ) : (
                "Continue"
              )
            ) : (
              "Continue Shopping"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
