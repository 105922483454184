import classes from "./BannerDiv.module.css";

export default function BannerDiv() {
  return (
    <div className={classes.container}>
      <div className={classes.contentContainer}>
        <span>Awaken your true potential</span>
        <span>
          Experience the performance, intimacy and confidence you deserve
        </span>
      </div>
    </div>
  );
}
