import { useContext, useEffect, useState } from "react";
import classes from "./AdminOrders.module.css";
import { firebaseContext } from "../Context/FirebaseContext";
import TransactionCard from "./TransactionCard";
import { FcNext } from "react-icons/fc";
import { AiOutlineSearch } from "react-icons/ai";
import { BsSquare, BsCheckSquare } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import AlertDialogDemo from "../RadixComponents/alertDialogDemo";

export default function AdminDash() {
  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showActions, setShowActions] = useState(false);

  const { db } = useContext(firebaseContext);

  const navigate = useNavigate();

  useEffect(() => {
    setShowActions(selected.length > 0 || checked);
  }, [checked, selected]);

  // const { pendingOrders } = useContext(firebaseContext);

  const [pendingOrders, setPendingOrders] = useState([]);
  console.log("pendingOrders: ", pendingOrders);

  const fetchOrders = useEffect(() => {
    onSnapshot(collection(db, "orders"), (snapshot) => {
      setPendingOrders(
        snapshot.docs
          .filter((doc) => doc.data().customer.status === "Pending")
          .map((doc) => ({ ...doc.data(), id: doc.id }))
      );
    });
  }, []);

  const calculateTotalSales = () => {
    let total = 0;
    pendingOrders.forEach((order) => {
      const orderTotal = order?.customer?.total;
      total += orderTotal; // Update the total within the loop
    });
    return total.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP",
    });
  };

  const markChecked = (action, order) => {
    if (action === "add") {
      setSelected((prev) => [...prev, order]);
    } else {
      setSelected((prev) => prev.filter((item) => item !== order));
    }
  };

  const checkAllOrders = () => {
    setSelected(!checked ? pendingOrders.map((order) => order?.id) : []);
    setChecked(!checked);
  };

  const openSnackbar = () => {
    setSnackbarOpen(true);
    setTimeout(() => {
      setSnackbarOpen(false);
    }, 3000);
  };

  const handleActionClick = (action) => {
    if (action === "fulfil") {
      pendingOrders.forEach((order) => {
        if (selected.includes(order?.id)) {
          const orderRef = doc(db, "orders", order?.id);
          const customerOrderRef = doc(db, "users", order?.customer?.userID, "orders", order?.customer?.orderNumber);

          updateDoc(orderRef, {
            "customer.status": "Completed",
          });

          updateDoc(customerOrderRef, {
            "customer.status": "Completed",
          });
        }
      });

      setSelected([]);
      setChecked(false);
      setShowActions(false);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerItem}>
          <span>Awaiting Dispatch: </span>
          <span>{pendingOrders.length}</span>
        </div>
        <div className={classes.headerItem}>
          <span>Total Sales: </span>
          <span>{calculateTotalSales()}</span>
        </div>
      </div>
      <div className={classes.bodyHeader}>
        <div className={classes.bodyHeaderTop}>
          {checked || selected.length > 0 ? (
            <BsCheckSquare onClick={checkAllOrders} style={{ marginRight: "1.5em" }} color="grey" size={17} />
          ) : (
            <BsSquare onClick={checkAllOrders} style={{ marginRight: "1.5em" }} color="grey" size={17} />
          )}
          <div className={classes.bodyHeaderItem} style={{ marginRight: "auto" }}>
            Transactions
            {selected.length > 0 && <span name="selected">| {selected.length} Selected</span>}
          </div>
          <div
            onClick={() => navigate("/admin/orders/all")}
            style={{ cursor: "pointer" }}
            className={classes.bodyHeaderItem}
          >
            See All Orders <FcNext style={{ marginLeft: "0em" }} />
          </div>
        </div>
        {showActions && pendingOrders.length > 0 && (
          <div className={classes.batchActions}>
            <div className={classes.actionsContainer}>
              <div onClick={() => handleActionClick("fulfil")} className={classes.action}>
                Fulfil Order
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classes.body}>
        {pendingOrders
          ?.sort((a, b) => b?.timestamp - a?.timestamp)
          .map((order) => (
            <TransactionCard
              openSnackbar={openSnackbar}
              allChecked={checked}
              markChecked={markChecked}
              key={order?.customer?.orderNumber}
              order={order}
            />
          ))}

        {snackbarOpen && (
          <div className={classes.snackbar}>
            <span>Refund Successful</span>
          </div>
        )}
      </div>
    </div>
  );
}
