import * as AlertDialog from "@radix-ui/react-alert-dialog";
import styles from "./alertDialogDemo.module.css";

import "@radix-ui/colors/blackA.css";
import "@radix-ui/colors/mauve.css";
import "@radix-ui/colors/red.css";
import "@radix-ui/colors/violet.css";
import { forwardRef } from "react";
import { ReactComponent as Loader } from "../Assets/loader.svg";

export const AlertDialogDemo = forwardRef(
  ({ handlRefundOrder, setShowActions, isRefunding }, ref) => {
    return (
      <AlertDialog.Root>
        <AlertDialog.Trigger asChild>
          <button className={styles.action}>
            {isRefunding ? (
              <Loader style={{ height: "2em", width: "2em" }} />
            ) : (
              "Refund Order"
            )}
          </button>
        </AlertDialog.Trigger>
        <AlertDialog.Portal>
          <AlertDialog.Overlay className={styles.AlertDialogOverlay} />
          <AlertDialog.Content className={styles.AlertDialogContent}>
            <AlertDialog.Title className={styles.AlertDialogTitle}>
              Are you absolutely sure?
            </AlertDialog.Title>
            <AlertDialog.Description className={styles.AlertDialogDescription}>
              This action cannot be undone. This will refund your customer
              immediately.
            </AlertDialog.Description>
            <div
              style={{
                display: "flex",
                gap: 25,
                justifyContent: "flex-end",
                padding: "0.5em",
              }}
            >
              <AlertDialog.Cancel asChild>
                <button
                  onClick={() => setShowActions(false)}
                  className={styles.Button + " " + styles.mauve}
                >
                  Cancel
                </button>
              </AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <button
                  ref={ref}
                  onClick={handlRefundOrder}
                  className={styles.Button + " " + styles.red}
                >
                  Yes, refund customer
                </button>
              </AlertDialog.Action>
            </div>
          </AlertDialog.Content>
        </AlertDialog.Portal>
      </AlertDialog.Root>
    );
  }
);
