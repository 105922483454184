import { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import AdminHeader from "../Components/AdminHeader";

export default function ProtectedAdminLayout({ user, isAdmin, redirectPath }) {
  const [isAuthorized, setIsAuthorized] = useState(isAdmin);

  if (!isAuthorized) {
    return <Navigate to={redirectPath} replace />;
  }

  return (
    <div style={{ height: "100dvh" }}>
      <AdminHeader />
      <Outlet />
    </div>
  );
}
