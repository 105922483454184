import { useContext, useEffect, useState } from "react";
import classes from "./AdminProducts.module.css";
import { firebaseContext } from "../Context/FirebaseContext";
import AdminProductItem from "./AdminProductItem";
import { BsCheckSquare, BsSquare } from "react-icons/bs";
import { FcNext } from "react-icons/fc";
import { useNavigate } from "react-router-dom";

export default function AdminProducts() {
  const { products } = useContext(firebaseContext);

  const [checked, setChecked] = useState(false);
  const [selected, setSelected] = useState([]);
  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    setShowActions(selected.length > 0 || checked);
  }, [checked, selected]);

  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.bodyHeader}>
        <div className={classes.bodyHeaderTop}>
          <div
            className={classes.bodyHeaderItem}
            style={{ marginRight: "auto" }}
          >
            Products
            <span style={{ fontSize: "0.8em" }} name="selected">
              | {products.length}{" "}
            </span>
          </div>
          <div
            onClick={() => navigate("/admin/products/create")}
            style={{ cursor: "pointer" }}
            className={classes.bodyHeaderItem}
          >
            Add New Product <FcNext style={{ marginLeft: "0em" }} />
          </div>
        </div>
      </div>
      {products?.map((product) => {
        return <AdminProductItem key={product.id} product={product} />;
      })}
    </div>
  );
}
