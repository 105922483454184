import classes from "./OrderDetailsItem.module.css";

export default function OrderDetailsItem({ order, item, packSize }) {
  if ((order, item)) {
    return (
      <div className={classes.container}>
        <div className={classes.item}>
          <div className={classes.itemImage}>
            <img src={order?.product?.image} alt="product" />
          </div>
        </div>
        <div className={classes.itemDetails}>
          <span className={classes.itemName}>{item?.name}</span>
          <div className={classes.itemQuantity}>Blister Pack: {item?.quantity}</div>
        </div>
        <div className={classes.itemTotal}>
          <div className={classes.itemTotalPrice}>
            <span>Total Price</span> <span>£{item?.price * order?.quantity}</span>
          </div>
          <div className={classes.itemTotalPrice}>
            <span>Quantity</span>
            {order?.quantity > 1 ? (
              <span
                style={{
                  fontWeight: "700",
                  fontSize: "0.9em",
                  padding: "0.4em 0.9em",
                  borderRadius: "50%",
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                {order?.quantity}
              </span>
            ) : (
              <span>{order?.quantity}</span>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
