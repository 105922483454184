import classes from "./SignUp.module.css";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { CgPassword } from "react-icons/cg";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../Context/UserContext";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Loader } from "../Assets/loader.svg";

export default function SignUp({ errors, setErrors, setShowErrors }) {
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  console.log("formDetails: ", formDetails);

  const [formIsValid, setFormIsValid] = useState(false);
  console.log("formIsValid: ", formIsValid);
  const [isChecked, setIsChecked] = useState(true);
  const [isAddingAddress, setIsAddingAddress] = useState(false);

  const { handleRegister } = useContext(UserContext);
  const { auth } = useContext(UserContext);

  const navigate = useNavigate();

  //Validate form
  const validateForm = (input) => {
    const { firstName, lastName, email, password, confirmPassword } =
      formDetails;
    const refs = {
      firstName: firstNameRef,
      lastName: lastNameRef,
      email: emailRef,
      password: passwordRef,
      confirmPassword: confirmPasswordRef,
    };

    if (
      !refs[input] ||
      !formDetails[input] ||
      formDetails[input].trim().length === 0
    ) {
      refs[input].current.style.border = "1px solid red";
      return;
    }

    if (input === "email") {
      const emailRegex = /^[a-zA-Z0-9._]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$(?!\.)/;

      if (!emailRegex.test(String(email).toLowerCase())) {
        refs[input].current.style.border = "1px solid red";
        setErrors({
          ...errors,
          email: "Invalid email address",
        });
        return;
      } else {
        setErrors({
          ...errors,
          email: "",
        });
      }
    }

    if (input === "password") {
      if (password.length < 6) {
        refs[input].current.style.border = "1px solid red";
        setErrors({
          ...errors,
          password: "Password must be at least 6 characters long",
        });
      } else {
        setErrors({
          ...errors,
          password: "",
        });
      }
    }

    if (input === "confirmPassword") {
      if (confirmPassword.length < 6) {
        refs[input].current.style.border = "1px solid red";
        return;
      }
    }

    refs[input].current.style.border = "1px solid #ccc";
  };

  //Validate form on change
  useEffect(() => {
    const { firstName, lastName, email, password, confirmPassword } =
      formDetails;

    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      password.length > 6 &&
      confirmPassword.length > 6 &&
      password === confirmPassword
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [formDetails]);

  //Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  //Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formIsValid) {
      setShowErrors(true);
      return;
    }

    const fullName = formDetails.firstName + " " + formDetails.lastName;

    try {
      const user = await handleRegister({
        nameText: fullName,
        emailText: formDetails.email,
        passwordText: formDetails.password,
        isSubscribed: isChecked,
        navigate: () => {
          navigate("/home", { replace: true });
        },
      });
    } catch (error) {
      alert(error.message);
      console.error(error.message);
    }
  };

  //Handle capture address form
  const handleCaptureAddressForm = async (e) => {
    e.preventDefault();

    if (!formIsValid) {
      setShowErrors(true);
      return;
    }

    setIsAddingAddress(true);
  };

  return (
    <>
      <form onSubmit={(e) => e.preventDefault()} className={`${classes.form}`}>
        <div className={"d-flex gap-2 w-100"}>
          <div ref={firstNameRef} className={classes.inputDivSmall}>
            <div
              style={{
                display: "flex",
                alignItem: "center",
                paddingRight: "0.5em",
                borderRight: "1.5px solid #ccc",
              }}
            >
              <BsPerson size={20} />
            </div>
            <input
              style={{ paddingLeft: "1em" }}
              type="text"
              placeholder="First Name"
              onChange={handleChange}
              name="firstName"
              value={formDetails.firstName}
            />
          </div>
          <div ref={lastNameRef} className={classes.inputDivSmall}>
            <div
              style={{
                display: "flex",
                alignItem: "center",
                paddingRight: "0.5em",
                borderRight: "1.5px solid #ccc",
              }}
            >
              <BsPerson size={20} />
            </div>
            <input
              style={{ paddingLeft: "1em" }}
              onChange={handleChange}
              name="lastName"
              type="text"
              placeholder="Surname"
              value={formDetails.lastName}
            />
          </div>
        </div>
        <div ref={emailRef} className={classes.inputDiv}>
          <div
            style={{
              display: "flex",
              alignItem: "center",
              paddingRight: "0.5em",
              borderRight: "1.5px solid #ccc",
            }}
          >
            <MdOutlineAlternateEmail size={20} />
          </div>
          <input
            onChange={handleChange}
            name="email"
            type="email"
            placeholder="email@email.com"
            value={formDetails.email}
          />
        </div>
        <div className={"d-flex gap-2 w-100"}>
          <div ref={passwordRef} className={classes.inputDivSmall}>
            <div
              style={{
                display: "flex",
                alignItem: "center",
                paddingRight: "0.5em",
                borderRight: "1.5px solid #ccc",
              }}
            >
              <CgPassword size={20} />
            </div>

            <input
              style={{ paddingLeft: "1em" }}
              onChange={handleChange}
              name="password"
              type="password"
              placeholder="Enter password"
              value={formDetails.password}
            />
          </div>
          <div ref={confirmPasswordRef} className={classes.inputDivSmall}>
            <div
              style={{
                display: "flex",
                alignItem: "center",
                paddingRight: "0.5em",
                borderRight: "1.5px solid #ccc",
              }}
            >
              <CgPassword size={20} />
            </div>
            <input
              style={{ paddingLeft: "1em" }}
              onChange={handleChange}
              name="confirmPassword"
              type="password"
              placeholder="Confirm password"
              value={formDetails.confirmPassword}
            />
          </div>
        </div>

        <div className={classes.checkDiv}>
          <input
            type="checkbox"
            id="checkbox"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
          />
          <label htmlFor="checkbox">
            Subscribe to exclusive discounts and free products
          </label>
        </div>

        <button
          type="submit"
          disabled={!formIsValid}
          onClick={handleSubmit}
          className={classes.submitButton}
        >
          Register
        </button>
      </form>
    </>
  );
}
