import { BsCheckCircleFill } from "react-icons/bs";
import classes from "./CheckoutSuccess.module.css";
import { useNavigate } from "react-router-dom";

export default function CheckoutSuccess() {
  const navigate = useNavigate();
  return (
    <div style={{ height: "100vh" }} className={classes.container}>
      <BsCheckCircleFill size={70} color="white" />

      <div className={classes.textContainer}>
        <span className={classes.text}>Order Placed!</span>

        <span className={classes.text}>
          Your order was placed successfully. You can also view your order in
          your account dashboard.
        </span>

        <button
          className={classes.continue}
          onClick={() => navigate("/", { replace: true })}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
