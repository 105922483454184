import { useContext } from "react";
import { firebaseContext } from "../Context/FirebaseContext";
import classes from "./ExpandedOrderCard.module.css";
import { Link } from "react-router-dom";

export default function ExpandedOrderCard({ item }) {
  console.log("item: ", item);
  // const { products } = useContext(firebaseContext);
  const { product } = item;
  console.log("product: ", product);

  if (product) {
    return (
      <div className={classes.expandedContainer}>
        <div className={classes.content}>
          <div className={classes.left}>
            <Link to={`/product/${product.id}`}>
              <img src={product.image} alt={product.name} />
            </Link>
          </div>
          <div className={classes.center}>
            <span
              style={{ fontSize: "0.8em", color: "black", fontWeight: "800" }}
            >
              {product.name}
            </span>
            <span
              style={{ fontSize: "0.8em", color: "black", fontWeight: "600" }}
              name="productPrice"
            >
              £{product.price}
            </span>
          </div>
          <div className={classes.right}></div>
        </div>
      </div>
    );
  }
}
