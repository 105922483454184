import { useContext, useEffect, useState } from "react";
import classes from "./Shop.module.css";
import { firebaseContext } from "../Context/FirebaseContext";
import ProductCard from "./ProductCard";
import { BsSortAlphaDown } from "react-icons/bs";

export default function Shop() {
  const { products } = useContext(firebaseContext);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [sortIsVisible, setSortIsVisible] = useState(false);

  useEffect(() => {
    setSortedProducts(products);
  }, [products]);

  const handleSort = (sortBy) => {
    if (sortBy === "priceLowToHigh") {
      const sorted = [...products].sort((a, b) => a.price - b.price);
      setSortedProducts(sorted);
    } else if (sortBy === "priceHighToLow") {
      const sorted = [...products].sort((a, b) => b.price - a.price);
      setSortedProducts(sorted);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <h3>Explore the range</h3>
      </div>

      <div className={classes.sort}>
        <div onClick={() => setSortIsVisible(!sortIsVisible)}>
          <BsSortAlphaDown size={20} />
          <span>Sort by</span>
        </div>

        {sortIsVisible && (
          <div className={classes.sortDiv}>
            <div
              onClick={() => handleSort("priceLowToHigh")}
              className={classes.sortItem}
            >
              Price, Low to High
            </div>
            <div
              onClick={() => handleSort("priceHighToLow")}
              className={classes.sortItem}
            >
              Price, High to Low
            </div>
          </div>
        )}
      </div>

      <div className={classes.body}>
        {sortedProducts.map((product) => {
          return <ProductCard key={product.id} product={product} />;
        })}
      </div>
    </div>
  );
}
