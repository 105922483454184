import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Components/Home";
import RootLayout from "./Layouts/RootLayout";
import Login from "./Components/Login";
import Product from "./Components/Product";
import Shop from "./Components/Shop";
import UserDash from "./Components/UserDash";
import { useContext, useEffect } from "react";
import { UserContext } from "./Context/UserContext";
import ProtectedRouteLayout from "./Layouts/ProtectedRouteLayout";
import UserOrders from "./Components/UserOrders";
import AdminOrders from "./Components/AdminOrders";
import CheckoutSuccess from "./Components/CheckoutSuccess";
import ProtectedAdminLayout from "./Layouts/ProtectedAdminLayout";
import AdminOrderDetails from "./Components/AdminOrderDetails";
import AdminProducts from "./Components/AdminProducts";
import AdminProductDetails from "./Components/AdminProductDetails";
import AdminDiscount from "./Components/AdminDiscount";
import AdminCreateCampaign from "./Components/AdminCreateCampaign";
import AdminOrdersAll from "./Components/AdminOrdersAll";
import AdminEditProduct from "./Components/AdminEditProduct";

import "@radix-ui/colors/mauve.css";

export default function App() {
  const { user } = useContext(UserContext);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<RootLayout />}>
          <Route index element={<Home />} />
          <Route path="product/:id" element={<Product />} />
          <Route path="shop" element={<Shop />} />

          <Route
            path="dashboard"
            element={<ProtectedRouteLayout user={user} redirectPath="/login" />}
          >
            <Route index element={<UserDash />} />
            <Route path="orders" element={<UserOrders />} />
          </Route>
        </Route>

        <Route
          path="/admin"
          element={
            <ProtectedAdminLayout
              isAdmin={user?.admin !== undefined && user?.admin === true}
              redirectPath="/login"
            />
          }
        >
          <Route path="/admin/orders" element={<AdminOrders />} />
          <Route path="/admin/orders/all" element={<AdminOrdersAll />} />
          <Route
            path={`/admin/edit-product/:id`}
            element={<AdminEditProduct />}
          />
          <Route path="orders/:id" element={<AdminOrderDetails />} />
          <Route path="products" element={<AdminProducts />} />
          <Route path="products/create" element={<AdminProductDetails />} />
          <Route path="discount" element={<AdminDiscount />} />
          <Route
            path="discount/create-coupon"
            element={<AdminCreateCampaign />}
          />
        </Route>

        <Route path="checkout-success" element={<CheckoutSuccess />} />

        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    )
  );

  return (
    <>
      <div className="App">
        <RouterProvider router={router} />
      </div>
    </>
  );
}
