import { BsTrash } from "react-icons/bs";
import Image from "./Image";
const ShowImage = ({ images, setImages }) => {
  const container = {
    display: "flex",
    flexWrap: "wrap",
    width: "90%",
  };

  const show = (image, index) => {
    return (
      <div key={index} style={{ position: "relative", marginTop: "1em" }}>
        <Image image={image} />
        {images.length > 0 && (
          <BsTrash
            onClick={() =>
              setImages((prevImages) =>
                prevImages.filter((img) => img !== image)
              )
            }
            style={{
              position: "absolute",
              right: "0.5em",
              cursor: "pointer",
              bottom: "40%",
              right: "50%",
            }}
            size={18}
            color="red"
          />
        )}
      </div>
    );
  };

  return <div style={container}>{images.map(show)}</div>;
};
export default ShowImage;
